import PopupLogin from "../components/popuplogin/popuplogin";
import PopupSearch from "../components/popupsearch/popupsearch";
import PopupOrder from "../components/popuporder/popuporder";
import Popup from "../components/popup/popup";
import PopupDelete from "../components/popupdelete/popupdelete";
import PopupMail from "../components/popupmail/popupmail";
import PopupSave from "../components/popupsave/popupsave";
import utilities, {deleteCookie, getCookie, setCookie} from "./utilities";
import initAdvancedLoginPopup from "../components/popuploginadvanced/popuploginadvanced";
import PopupGainable from "../components/popupgainable/popupgainable";
import Player from "@vimeo/player";
import {Formio} from "formiojs";
import $ from 'jquery';


let seconds = 0;
let urlParams = new URLSearchParams(window.location.search);
let apiUrl = window.location.protocol + "//" + window.location.host;
const teaserAllButton = document.querySelectorAll('.TeaserFav.document_docga');
const teaserAllFav = document.querySelectorAll('.TeaserFav-favorite');
const formOrder = document.getElementById('formGuidePro');
let thermorMasterHeader = document.querySelectorAll('.FullHeaderTop-item--horizonLogo');
if (document.querySelector('.role_installer')) {
    var roleInstallerValue = JSON.parse(document.querySelector('.role_installer').value);
}

if (document.querySelector('.user-connected') && roleInstallerValue?.enableMaxxing && roleInstallerValue?.roleInstaller) {
    thermorMasterHeader.forEach(function (item) {
        if (window.getComputedStyle(item).display !== "none") {
            let nv = item.querySelector('.EngagementProgramHeader--niveau .desktop-level');
            let nvMb = item.querySelector('.EngagementProgramHeader--niveau .mob-level');
            let counterValue = item.querySelector('.EngagementProgramHeader--lingot span');
            let counterValueStatut = document.querySelector('.blockMonProfilStatut--niveau .blockMonProfilStatut-count');
            let lingot = item.querySelector('.EngagementProgramHeader--lango span');
            let statusMaxxing = item.querySelector('.EngagementProgramHeader--status .EngagementProgramHeader--statusName');
            let currentStatusMaxxing = item.querySelector('.status-maxxing')?.getAttribute('data-level');
            let currentcounterValue = item.querySelector('.EngagementProgramHeader--lingot')?.getAttribute('data-nextlevel');
            let currentLingot = item.querySelector('.lingot-maxxing')?.getAttribute('data-lingot');
            let logoStatusMaxxing = item.querySelector('.EngagementProgramHeader--statusLogo img');
            let infoBullLogo = item.querySelector('.infoBull--logo img');
            let notifeQueteModal = document.querySelector('.notifeQueteStatut.quete');
            let notifeQueteModalTitle = document.querySelector('.notifeQueteStatut.quete .notifeQueteStatut-title');
            let notifeSubQueteModal = document.querySelector('.notifeSubQueteStatut.subquete');
            let notifeSubQueteModalTitle = document.querySelector('.notifeSubQueteStatut.subquete .notifeSubQueteStatut-title');
            let notifeStatusModal = document.querySelector('.notifeQueteStatut.status');
            let NotifeBadge = document.querySelector('.NotifeBadge');
            let NotifeBadgeMessage = document.querySelector('.NotifeBadge-message');
            let NotifeBadgeImg = document.querySelector('.NotifeBadge-visuel img');
            let notifeQueteStatuttopstatus = document.querySelector('.notifeQueteStatut-top-status');
            let notifeQueteStatuttoplogo = document.querySelector('.notifeQueteStatut-top-logo');
            const splitlogoStatusMaxxing = logoStatusMaxxing?.getAttribute('src').split('/')
            const srclogoStatusMaxxing = splitlogoStatusMaxxing?.slice(0, splitlogoStatusMaxxing.length - 1).join("/") + "/";
            const splitInfobulllogoStatusMaxxing = logoStatusMaxxing?.getAttribute('src').split('/')
            const srcInfobulllogoStatusMaxxing = splitInfobulllogoStatusMaxxing?.slice(0, splitInfobulllogoStatusMaxxing.length - 1).join("/") + "/";

            if (notifeQueteStatuttopstatus !== null) notifeQueteStatuttopstatus.textContent = currentStatusMaxxing
            if (infoBullLogo !== null) infoBullLogo.setAttribute('src', srcInfobulllogoStatusMaxxing + currentStatusMaxxing.toLowerCase() + 'Logo.png');
            if (notifeQueteStatuttoplogo !== null) notifeQueteStatuttoplogo.setAttribute('src', notifeQueteStatuttoplogo?.getAttribute('src') + '/bundles/thermorprosite/images/engagementProgram/' + currentStatusMaxxing?.toLowerCase() + 'Logo.png');

            logoStatusMaxxing?.setAttribute('src', srclogoStatusMaxxing + currentStatusMaxxing.toLowerCase() + '.png')
            let niveauBar = parseInt(parseInt(counterValue?.innerText.split('/')[0].trim()) / parseInt(counterValue?.innerText.split('/')[1].trim()) * 100);
            let progressLevels = document.querySelectorAll('.progress-level');
            let currentCXP = parseInt(counterValue?.innerText.split('/')[0].trim());

            function updateProgressBar(progressLevel, niveauBar) {
                if (niveauBar > 99) {
                    progressLevel.style.width = "0%";
                } else {
                    progressLevel.style.width = niveauBar.toString() + "%";
                }
            }

            progressLevels.forEach(function (progressLevel) {
                updateProgressBar(progressLevel, niveauBar);
            });

            document.addEventListener("DOMContentLoaded", event => {
                const videoPlayers = document.querySelectorAll('.VideoPlayer');// an array of all videoplayers on page
                if (videoPlayers == null || videoPlayers.length < 1) {
                    return;
                }
                videoPlayers.forEach(videoPlayer => {
                    initVideoPlayer(videoPlayer);
                })
            }, {'once': true});


            function apiMaxxing(cxpValueCoupon = 0, cxp_reward = {}, clingot_reward = {}) {
                fetch('/info-maxxing', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        const delay = 4000;
                        notifeStatusModal.setAttribute('aria-hidden', 'false');
                        let cxpMaxxingDom = document.querySelector('.cxp-maxxing');
                        cxpMaxxingDom.innerHTML = '';
                        let lastCxp = parseFloat(counterValue?.innerText.split('/')[0].trim());

                        /* ************************* c_xp/c_nextlevel ********************************** */
                        nv.textContent = "Niveau " + data.NIVEAU;
                        statusMaxxing.textContent = data.STATUT.charAt(0).toUpperCase() + data.STATUT.substring(1).toLowerCase();
                        notifeQueteStatuttopstatus.textContent = data.STATUT.charAt(0).toUpperCase() + data.STATUT.substring(1).toLowerCase();
                        lingot.textContent = Math.round(parseFloat(data.C_LINGOT));
                        nvMb.textContent = "N." + data.NIVEAU + "-";
                        counterValue.innerHTML = Math.round(parseFloat(data.C_XP)) + '/' + Math.round(parseFloat(data.C_XP) + parseFloat(data.C_NEXTLEVEL));
                        if (counterValueStatut !== null) counterValueStatut.innerHTML = data.C_XP + '/' + (parseFloat(data.C_XP) + parseFloat(data.C_NEXTLEVEL));

                        let newCounterValue = (data.C_XP + '/' + (parseFloat(data.C_XP) + parseFloat(data.C_NEXTLEVEL))).split('/')[1].replace('\n', '').trim();
                        let gainCounterValue = cxpValueCoupon == 0 ? (newCounterValue - currentcounterValue) : cxpValueCoupon;
                        gainCounterValue = parseFloat(gainCounterValue).toFixed(2);
                        let newNiveauBar = parseFloat(parseFloat(data.C_XP) / parseFloat(newCounterValue) * 100);

                        if ((currentcounterValue !== newCounterValue) && (gainCounterValue !== 0)) {
                            progressLevels.forEach(function (progressLevel) {
                                updateProgressBar(progressLevel, newNiveauBar);
                            });
                            item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML = ""
                            item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                                <div class="EngagementProgramHeader--red-bg">
                                    <div class="EngagementProgramHeader--scintillement level">
                                        <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                        <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                        <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                        <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                                    </div>
                                        <div class="EngagementProgramHeader--level-coefficient">
                                            <div class="blurred-overlay"></div>
                                                <span class="level-coefficient">
                                                    ${gainCounterValue}
                                                </span>
                                            </div>
                                </div>`;
                        }

                        /* ************************** levelMaxxing  */
                        let currentLevelMaxxing = item.querySelector('.cxp-maxxing')?.getAttribute('data-currentLevel');
                        let newLevelMaxxing = data.NIVEAU;
                        let passNewLevel = item.classList.contains('FullHeaderTop-item--horizonLogo-mobile') ? "Bravo, niveau" : "Vous avez atteint le niveau";


                        if (currentLevelMaxxing !== newLevelMaxxing) {
                            item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML = ""
                            item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                            <div class="EngagementProgramHeader--red-bg nextLevel">
                                <div class="EngagementProgramHeader--scintillement level">
                                    <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                                </div>
                                <p>${passNewLevel} ${newLevelMaxxing} !</p>
                            </div>
                            `
                        }

                        /* **************************  StatusMaxxing   */
                        let newStatusMaxxing = data.STATUT.charAt(0).toUpperCase() + data.STATUT.substring(1).toLowerCase();
                        if (currentStatusMaxxing !== newStatusMaxxing) {
                            let notifStatusLingots = document.querySelector('.notifeQueteStatut-item-lingots .notifeQueteStatut-coef')?.firstElementChild;
                            notifStatusLingots.innerHTML = data.SUPERPOUVOIR.LINGOTS + '%';
                            let notifStatusXp = document.querySelector('.notifeQueteStatut-item-xp .notifeQueteStatut-coef')?.firstElementChild;
                            notifStatusXp.innerHTML = data.SUPERPOUVOIR.XP + '%';

                            // Change aria-hidden to "false" to show the modal
                            if (notifeStatusModal.getAttribute('aria-hidden') === 'false') {
                                setTimeout(function () {
                                    notifeStatusModal.setAttribute('aria-hidden', 'true');
                                }, 5000);
                            }

                            if ((data.SUPERPOUVOIR.LINGOTS === 0 || data.SUPERPOUVOIR.XP === 0) || (data.SUPERPOUVOIR.LINGOTS == '' || data.SUPERPOUVOIR.XP == '')) {
                                document.querySelector('.notifeQueteStatut.status').classList.add('zeroBonus');
                                document.querySelector('.infoBull--desc').style.display = 'none';
                            }

                            notifeStatusModal.setAttribute('aria-hidden', 'false');
                            logoStatusMaxxing.setAttribute('src', srclogoStatusMaxxing + newStatusMaxxing.toLowerCase() + '.png');
                            notifeStatusModal.querySelector('.notifeQueteStatut-top-logo').setAttribute('src', "/bundles/thermorprosite/images/engagementProgram/" + newStatusMaxxing.toLowerCase() + 'Logo.png');
                            infoBullLogo.setAttribute('src', srcInfobulllogoStatusMaxxing + newStatusMaxxing.toLowerCase() + 'Logo.png');
                            item.querySelector('.status-maxxing').innerHTML = ""
                            item.querySelector('.status-maxxing').innerHTML += `
                     <div class="EngagementProgramHeader--red-bg">
                         <div class="EngagementProgramHeader--scintillement">
                             <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                             <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                             <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                         </div>
                         <div class="EngagementProgramHeader--text-animation">
                             <span class="currentStatus"> ${currentStatusMaxxing} </span>
                             <span class="nextStatus"> ${newStatusMaxxing} </span>
                         </div>
                     </div>`;
                        }
                        /* ****************** LingotMaxxing ************************** */
                        let newLingot = data.C_LINGOT;
                        if (parseFloat(currentLingot) !== parseFloat(newLingot)) {
                            item.querySelector('.lingot-maxxing').innerHTML = ""
                            item.querySelector('.lingot-maxxing').innerHTML += `
                     <div class="EngagementProgramHeader--red-bg">
                         <span class="EngagementProgramHeader--langoLogo-animation"><img src="/bundles/thermorprosite/icons/lingoWhite.svg" /></span>
                          <div class="EngagementProgramHeader--scintillement">
                              <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                              <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                              <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                              <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                          </div>
                          <div class="EngagementProgramHeader--level-coefficient">
                              <span class="lango-coefficient"> ${clingot_reward.reward ? clingot_reward.reward.toFixed(2) : (newLingot - currentLingot)} </span>
                          </div>
                        </div>`;
                            if(clingot_reward.bonus) {
                                setTimeout(() => {
                                    item.querySelector('.lingot-maxxing').innerHTML += `
                        <div class="EngagementProgramHeader--red-bg">
                         <span class="EngagementProgramHeader--langoLogo-animation"><img src="/bundles/thermorprosite/icons/lingoWhite.svg" /></span>
                          <div class="EngagementProgramHeader--scintillement">
                              <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                              <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                              <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                              <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                          </div>
                          <div class="EngagementProgramHeader--level-coefficient">
                              <span class="lango-coefficient"> ${clingot_reward.bonus ? clingot_reward.bonus.toFixed(2) : 0} </span>
                          </div>
                        </div>`;
                                }, delay);
                            }
                        }


                        let newCXP = data.C_XP;
                        let gainCXP = cxpValueCoupon == 0 ? newCXP - lastCxp < 0 ? parseFloat(newCXP).toFixed(2) : parseFloat(newCXP - lastCxp).toFixed(2) : cxpValueCoupon;

                        if (lastCxp !== newCXP && gainCXP !== 0) {
                            progressLevels.forEach(function (progressLevel) {
                                updateProgressBar(progressLevel, newNiveauBar);
                            });
                            var gainCXPPartieEntiere = Math.floor(gainCXP);
                            var gainCXPPartieEntiereToShow = gainCXPPartieEntiere === 0 ? "0.00" : gainCXPPartieEntiere.toFixed(2);
                            var gainCXPPartieDecimale = parseFloat(gainCXP - gainCXPPartieEntiere).toFixed(2);

                            item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML = ""
                            item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                        <div class="EngagementProgramHeader--red-bg">
                            <div class="EngagementProgramHeader--scintillement level">
                                <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                            </div>
                                <div class="EngagementProgramHeader--level-coefficient">
                                    <div class="blurred-overlay"></div>
                                        <span class="level-coefficient">
                                            ${cxp_reward.reward ? cxp_reward.reward.toFixed(2) :gainCXPPartieEntiereToShow}
                                        </span>
                                    </div>
                        </div>`;
                            if(cxp_reward.bonus || gainCXPPartieDecimale > 0){
                                setTimeout(() => {
                                    item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                        <div class="EngagementProgramHeader--red-bg">
                            <div class="EngagementProgramHeader--scintillement level">
                                <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                            </div>
                                <div class="EngagementProgramHeader--level-coefficient">
                                    <div class="blurred-overlay"></div>
                                        <span class="level-coefficient">
                                            ${cxp_reward.bonus ? cxp_reward.bonus.toFixed(2) : gainCXPPartieDecimale}
                                        </span>
                                    </div>
                        </div>`;
                                }, delay);
                            }
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });

            }

            function apiTarget(targets) {
                let dataToSend = {
                    codes: targets
                };
                fetch('/info-target', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: JSON.stringify(dataToSend)
                })
                    .then(response => response.json())
                    .then(data => {
                        let trophies = data.TROPHIES;
                        let subquests = data.SUBQUESTS;
                        let quests = data.QUESTS;
                        for (const [key, value] of Object.entries(trophies)) {
                            NotifeBadgeMessage.textContent = "Vous venez de remporter le badge \"" + value.NAME + "\""
                            NotifeBadgeImg.setAttribute('src', value.IMAGE_ON_TEXT);
                            NotifeBadgeImg.setAttribute('style', 'max-width:100px;');
                            NotifeBadge.setAttribute('style', 'display:block;');
                        }
                        for (const [key, value] of Object.entries(subquests)) {
                            notifeSubQueteModalTitle.textContent = value.NAME + " - " + value.description;
                            notifeSubQueteModal.setAttribute('aria-hidden', 'true');
                        }
                        for (const [key, value] of Object.entries(quests)) {
                            if (value.NAME) {
                                notifeQueteModalTitle.textContent = value.NAME;
                                notifeQueteModal.setAttribute('aria-hidden', 'true');
                            }
                        }
                    })
            }

            function sendOrderRequest(transactionUid, orderPoppin) {
                var tries = 2;

                fetch('/data-order', { //first

                    method: 'POSt',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(transactionUid)
                })
                    .then(response => {
                        if (!response.ok)
                            throw new Error();
                        return response.json()
                    })
                    .then(function (data) {
                        apiMaxxing();
                        orderPoppin.querySelector('.orderPopin-formTitle').style.display = "none";
                        orderPoppin.querySelector('.masters_order_form').style.display = "none";
                        orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                        orderPoppin.querySelector('.orderPopin-valideMsg').style.display = "block";
                    })
                    .catch(function (error) {
                        fetch('/data-order', { //second

                            method: 'POSt',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(transactionUid)
                        })
                            .then(response => {
                                if (!response.ok)
                                    throw new Error();
                                return response.json()
                            })
                            .then(function (data) {
                                orderPoppin.querySelector('.orderPopin-formTitle').style.display = "none";
                                orderPoppin.querySelector('.masters_order_form').style.display = "none";
                                orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                                orderPoppin.querySelector('.orderPopin-valideMsg').style.display = "block";
                                apiMaxxing();
                            })
                            .catch(function (error) {
                                fetch('/data-order', { //third
                                    method: 'POSt',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(transactionUid)
                                })
                                    .then(response => {
                                        if (!response.ok)
                                            throw new Error();
                                        return response.json()
                                    })
                                    .then(function (data) {
                                        orderPoppin.querySelector('.orderPopin-formTitle').style.display = "none";
                                        orderPoppin.querySelector('.masters_order_form').style.display = "none";
                                        orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                                        orderPoppin.querySelector('.orderPopin-valideMsg').style.display = "block";
                                        apiMaxxing();
                                    })
                                    .catch(function (error) {
                                        //cancel maxxing transaction
                                        fetch('/cancel-maxxing-transaction', {
                                            method: 'POSt',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify(transactionUid)

                                        })
                                            .then(response => response.json())
                                            .then(function (data) {
                                                orderPoppin.querySelector('.orderPopin-errorMsg').style.display = "block";
                                                orderPoppin.querySelector('.orderPopin-errorMsg').innerHTML = 'Une erreur s’est produite au moment de l’enregistrement de votre commande. Nous avons annulé la transaction et vous prions de nous excuser.';
                                                orderPoppin.querySelector('.orderPopin-formTitle').style.display = "none";
                                                orderPoppin.querySelector('.masters_order_form').style.display = "none";
                                                orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                                                orderPoppin.querySelector('.orderPopin-valideMsg').style.display = "none";

                                            })
                                            .catch(function (error) {
                                                console.error('There was a problem with the fetch cancel-maxxing-transaction operation:', error);
                                            });
                                        console.error('There was a problem with the fetch operation:');
                                    });
                                console.error('There was a problem with the fetch operation:');
                            });
                        console.error('There was a problem with the fetch operation');
                    });
            }

            function sendPostRequest(dataToSend) {
                const url = '/info-doc';
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(dataToSend)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.webTransaction_request_response_code.ticket_msg.value != "") {
                            let targets = data.webTransaction_request_response_code.ticket_msg.value.split(/\r?\n/);
                            apiTarget(targets)
                        }
                        let rewards = data.webTransaction_request_response_code.rewards;
                        let counters = data.webTransaction_request_response_code.counters.counter;
                        let totalXpRewards = counters.filter((counter) => counter.name == "C_CUMULXP");
                        let totalLingotsRewards = counters.filter((counter) => counter.name == "C_LINGOT");
                        let cxpRewards = rewards.filter((reward) => reward.counter_name == "C_XP");
                        let clingotRewards = rewards.filter((reward) => reward.counter_name == "C_LINGOT");
                        apiMaxxing(0, extractRewards(totalXpRewards,cxpRewards), extractRewards(totalLingotsRewards,clingotRewards));
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }

            let btnCoupon = document.querySelector('.coupon--btn');
            let valueCoupon = document.querySelector('.coupon--input');
            let couponInfo = document.querySelector('.coupon--info');

            if (valueCoupon) {
                valueCoupon.addEventListener('keyup', function (e) {
                    couponInfo.style.display = 'none';
                    couponInfo.classList.remove('valide');
                    couponInfo.classList.remove('invalid');
                    valueCoupon.classList.remove('valide');
                    valueCoupon.classList.remove('invalid');
                });

                valueCoupon.addEventListener('input', function () {

                    let couponVal = valueCoupon.value;
                    // Transform to uppercase
                    couponVal = couponVal.toUpperCase();
                    // Remove spaces
                    couponVal = couponVal.replace(/\s/g, '');
                    // Remove line breaks
                    couponVal = couponVal.replace(/(\r\n|\n|\r)/gm, '');
                    // Update the input value
                    valueCoupon.value = couponVal;
                })
            }

            if (btnCoupon) {
                btnCoupon.addEventListener('click', function (e) {
                    let couponLoader = $('.coupon--loader');
                    couponLoader.show();
                    $(btnCoupon).prop( "disabled", "disabled" );
                    e.preventDefault();
                    let coupon = {
                        valueCoupon: valueCoupon.value
                    };
                    fetch('/engagement', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(coupon)
                    })
                        .then(response => {
                            return response.json();
                        })
                        .then((json) => {
                            let couponWin = json.couponWin;
                            let rewards = json.rewards;
                            let counters = json.counters;
                            if (couponWin.valid) {
                                couponInfo.style.display = 'inline-flex';
                                couponInfo.classList.add('valide');
                                couponInfo.classList.remove('invalid');
                                valueCoupon.classList.add('valide');
                                valueCoupon.classList.remove('invalid');
                                couponInfo.querySelector('img').setAttribute('src', '/bundles/thermorprosite/images/profil/ic-valide.svg')
                                couponInfo.querySelector('span').textContent = "Votre coupon a bien été pris en compte, vous gagnez " + couponWin.value + " XP";
                                if (couponWin.ticket_msg.value != "") {
                                    let targets = couponWin.ticket_msg.value?.split(/\r?\n/);

                                    apiTarget(targets)
                                }
                                let totalXpRewards = counters.filter((counter) => counter.name == "C_CUMULXP");
                                let totalLingotsRewards = counters.filter((counter) => counter.name == "C_LINGOT");
                                let cxpRewards = rewards.filter((reward) => reward.counter_name == "C_XP");
                                let clingotRewards = rewards.filter((reward) => reward.counter_name == "C_LINGOT");

                                apiMaxxing(couponWin.value, extractRewards(totalXpRewards, cxpRewards), extractRewards(totalLingotsRewards, clingotRewards));
                            } else {
                                couponInfo.style.display = 'inline-flex';
                                couponInfo.classList.add('invalid');
                                couponInfo.classList.remove('valide');
                                valueCoupon.classList.add('invalid');
                                valueCoupon.classList.remove('valide');
                                couponInfo.querySelector('img').setAttribute('src', '/bundles/thermorprosite/images/profil/ic-erreur.svg')
                                couponInfo.querySelector('span').textContent = "Le coupon que vous avez renseigné n'est pas valide ou a déjà été utilisé, merci de vérifier votre saisie"
                            }
                            couponLoader.hide();
                            $(btnCoupon).prop( "disabled", false );
                        })
                        .catch(error => {
                            console.error(error);
                            couponLoader.hide();
                            $(btnCoupon).prop( "disabled", false );
                        });
                });
            }

            // Sélectionnez le div que vous souhaitez surveiller
            const divToObserve = document.getElementById('video-pop');

            if (divToObserve) {
                // Créez une instance de MutationObserver
                const observer = new MutationObserver(function (mutationsList, observer) {
                    // Parcourez les mutations détectées
                    for (let mutation of mutationsList) {
                        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                            // Si des nœuds ont été ajoutés à notre div cible
                            initVideoPlayer();
                        }
                    }
                });

                // Configurez l'observateur pour surveiller les ajouts de nœuds au div cible
                const config = {childList: true, subtree: true};
                observer.observe(divToObserve, config);
            }

            // Pour arrêter d'observer plus tard (par exemple, si vous n'en avez plus besoin)
            // observer.disconnect();
            function initVideoPlayer() {
                let childVideo = document.querySelector('.BlockVideo-mainiframe') // defined as a let, can either be a div or an iframe -- redefined if elem changes
                let videoUrl = childVideo.getAttribute('src');

                videoUrl = videoUrl.replace('?autoplay=1', "");
                const parts = videoUrl.split('/');
                const videoId = decodeURIComponent(parts[parts.length - 1]);
                let player = new Player(childVideo);
                let dataToSend = {
                    videoId: videoId
                };
                let fetchCompleted = false;

                fetch('/infos-video', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(dataToSend)
                })
                    .then(response => response.json())
                    .then(data => {
                        let timerVideo = data.maxxingReadingPercentage || 90;
                        player.on("timeupdate", function () {
                            player.getCurrentTime().then(function (currentTime) {
                                player.getDuration().then(function (totalDuration) {
                                    if (!isNaN(currentTime) && !isNaN(totalDuration)) {
                                        var widthPercentage = (currentTime / totalDuration) * 100;
                                        if (widthPercentage > timerVideo && !fetchCompleted) {
                                            fetchCompleted = true;
                                            sendPostRequest(dataToSend);
                                        }
                                    } else {
                                        console.log("Invalid values for current time or total duration.");
                                    }
                                });
                            });
                        });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }


            teaserAllFav.forEach(teaserFav => {
                teaserFav.addEventListener('click', function (e) {
                    e.stopPropagation();
                    let teaserFavSelected = teaserFav.getAttribute('aria-selected');
                    if (teaserFavSelected === 'true') {
                        let dataToSend = {
                            documentIdFav: teaserFav.getAttribute('data-document-id')
                        };
                        sendPostRequest(dataToSend);
                    }
                });
            });
            const liseusePdf = document.getElementById('liseuse-pdf-container');
            if (liseusePdf) {
                let documentId = liseusePdf.getAttribute('document_id');
                let dataToSend = {
                    documentId: documentId
                };
                sendPostRequest(dataToSend);
            }
            document.addEventListener('DOMContentLoaded', function () {
                setTimeout(() => {
                    const buttonDown = document.getElementsByClassName('reading__download');
                    if (buttonDown.item(0)) {
                        buttonDown.item(0).addEventListener('click', function () {
                            const currentUrl = window.location.href;
                            const parts = currentUrl.split('/');
                            const namePdf = decodeURIComponent(parts[parts.length - 1]);
                            let dataToSend = {
                                namePdf: namePdf
                            };
                            sendPostRequest(dataToSend);
                        });
                    }
                }, 10000)

            });
            if (formOrder !== null) {
                if (formOrder.dataset.formResult === 'success') {
                    let dataToSend = {
                        order: 'COMGUI'
                    };
                    sendPostRequest(dataToSend);
                }
            }
            const saveDimSubmit = document.getElementById("saveDim-submit");
            if (saveDimSubmit) {
                saveDimSubmit.addEventListener("click", function (e) {
                    e.preventDefault();
                    let successSave = document.querySelector('.successSave');
                    if (successSave) {
                        let breadcrumb = document.querySelector('.Breadcrumbs-list');
                        const liElements = breadcrumb.querySelectorAll("li");
                        const pacs = {
                            'piscine': 'piscine',
                            'air': 'air',
                            'chauffe': 'chauffe'
                        };
                        for (const key in pacs) {
                            liElements.forEach(function (li) {
                                const liText = li.textContent.toLowerCase();

                                if (liText.includes(key)) {
                                    let dataToSend = {
                                        simulHome: pacs[key]
                                    };
                                    e.preventDefault();

                                    sendPostRequest(dataToSend);
                                }
                            });
                        }
                    }
                });
            }
            let pathnamePage = window.location.pathname;
            let messageScroll = false;
            let isStillOnPage = false;
            let timeOut = 0;
            let intervalTimeOut = 0;
            let isValorisedData = document.querySelector('.is_valorised');
            if (isValorisedData) {
                let dataParse = JSON.parse(isValorisedData.value);
                let viewTypeContent = dataParse.viewTypeContent;
                let isValorised = dataParse.isValorised;
                let timerPage = dataParse?.timerPage || '';
                let percentageScroll = dataParse?.percentageScroll || '';

                document.addEventListener('DOMContentLoaded', function () {
                    if (viewTypeContent === 'full' && isValorised) {
                        window.addEventListener('scroll', function () {
                            const currentScrollPosition = window.scrollY || window.pageYOffset;
                            const documentHeight = document.documentElement.scrollHeight;
                            const scrollThreshold = percentageScroll ? (+percentageScroll / 100) : 0.5;
                            const scrollPositionThreshold = documentHeight * scrollThreshold;

                            if (currentScrollPosition > scrollPositionThreshold && getCookie(pathnamePage) !== "true") {
                                setCookie(pathnamePage, true, false, false, 30);
                                messageScroll = true;
                            }
                        });

                        timeOut = setTimeout(() => {
                            isStillOnPage = true;
                        }, timerPage ? (+timerPage * 1000) : 10000);

                        intervalTimeOut = setInterval(() => {
                            if (messageScroll && isStillOnPage) {
                                let dataSent = {
                                    pathnamePage: pathnamePage
                                };
                                sendPostRequest(dataSent);
                                timeOut = clearTimeout(timeOut)
                                messageScroll = false;
                                isStillOnPage = false;
                                clearInterval(intervalTimeOut);
                            }
                        }, 1)
                    }

                });
            }


            var formElement = document.getElementById('masters_decla_tiers');
            if (formElement) {
                Formio.createForm(formElement, 'https://vfhdbhdalpkaqwk.form.io/themastersdeclatiers').then(form => {
                    if (form) {
                        var hiddenInput = document.getElementById("input-user").value;
                        var userData = JSON.parse(hiddenInput);
                        var account = userData.account;
                        var contact = userData.contact;
                        var accountExternalIdForm = document.querySelector('.formio-component-proAccountExternalId');
                        if (accountExternalIdForm) {
                            accountExternalIdForm.style.display = "none";
                        }
                        var contactExternalIdForm = document.querySelector('.formio-component-proContactExternalId');
                        if (contactExternalIdForm) {
                            contactExternalIdForm.style.display = "none";
                        }
                        form.submission = {
                            data: {
                                civilite: contact.titleLabel ?? '',
                                nom: contact.lastName ?? '',
                                prenom: contact.firstName ?? '',
                                email: contact.email,
                                proContactExternalId: contact.externalId ?? '',
                                proAccountExternalId: account['externalId'] ?? ''
                            }
                        };
                        form.on('render', function () {
                            form.getComponent('civilite').setValue(contact.titleLabel ?? '');
                            form.getComponent('nom').setValue(contact.lastName ?? '');
                            form.getComponent('prenom').setValue(contact.firstName ?? '');
                            form.getComponent('email').setValue(contact.email);
                            form.getComponent('proContactExternalId').setValue(contact.externalId ?? '');
                            form.getComponent('proAccountExternalId').setValue(account['externalId'] ?? '');
                        });

                        form.render();
                        form.on('submit', function (submission) {
                        });
                        form.on('submitDone', function (submission) {
                            let equipment = document.querySelector('.equipment_path').value;
                            let equipmentPath = JSON.parse(equipment);
                            var currentUrl = window.location.href;
                            window.location.href = currentUrl.replace('/masters-tiers', equipmentPath);
                        });
                    }
                });
            }
            const cardProduct = document.querySelectorAll('.masters_order_form');
            var transactionId = null;
            cardProduct.forEach(function (item) {
                Formio.createForm(
                    document.getElementById(item.getAttribute('id')),
                    'https://vfhdbhdalpkaqwk.form.io/themastersorder',
                    {
                        hooks: {
                            beforeSubmit: (submission, next) => {
                                let orderVisible = document.querySelector('.orderPopin.is-visible');
                                let loaderHtml = '<img class="loader-thermor" src="/bundles/thermorprosite/images/animation/thermor-animation-chargement.svg" alt="chargement"/>';
                                $('.orderPopin.is-visible .orderPopin-actions').prepend(loaderHtml);
                                $('.orderPopin.is-visible .orderPopin-valide').parent().css('display', 'none');
                                $('.orderPopin.is-visible .orderPopin-cancel').parent().css('display', 'none');
                                var hiddenInput = orderVisible.querySelector(".input-product").value;

                                var productData = JSON.parse(hiddenInput);
                                var dataToSend = {
                                    productId: productData.productId,
                                    lingotProduct: productData.lingotProduct
                                };
                                fetch('/info-doc', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(dataToSend)
                                })
                                    .then(response => response.json())
                                    .then(function (data) {
                                        let submitErrors = null;
                                        if (data.error) {
                                            submitErrors = [];
                                            submitErrors.push({
                                                message: data.error
                                            });
                                            orderVisible.querySelector('.orderPopin-errorMsg').style.display = "block";
                                            orderVisible.querySelector('.orderPopin-errorMsg').innerHTML = 'Détail de l’erreur: ' + data.error;
                                            orderVisible.querySelector('.orderPopin-formTitle').style.display = "none";
                                            orderVisible.querySelector('.masters_order_form').style.display = "none";
                                            orderVisible.querySelector('.orderPopin-cancel').style.display = "none";
                                        }
                                        transactionId = data.webTransaction_request_response_code.transaction_uid.value;
                                        next(submitErrors);
                                    })
                                    .catch(function (error) {
                                        console.error('There was a problem with the fetch operation:', error);
                                    });

                            }
                        }
                    }
                ).then(function (form) {
                    if (form) {
                        const orderProductBtn = document.querySelectorAll('.ProgramCardProduct .ProgramCardProduct-order');
                        if (orderProductBtn) {

                            orderProductBtn.forEach(function (item) {
                                const orderPoppin = item.closest('.ProgramCardProduct').nextElementSibling;
                                const cancelBtn = orderPoppin.querySelectorAll('.cancel-order');
                                item.addEventListener('click', function () {
                                    orderPoppin.classList.add('is-visible');
                                    var hiddenInput = orderPoppin.querySelector(".input-product").value;

                                    var productData = JSON.parse(hiddenInput);
                                    var dataToSend = {
                                        productId: productData.productId,
                                        lingotProduct: productData.lingotProduct
                                    };
                                    /************************************************* v* */
                                    var productValidate = orderPoppin.querySelector(".validate-product").value;
                                    var productValidateData = JSON.parse(productValidate);
                                    let account = productValidateData.account;
                                    let contact = productValidateData.contact;
                                    let orderData = productValidateData.orderData;
                                    let orderTiers = productValidateData.orderTiers;
                                    form.submission = {
                                        data: {
                                            nom: orderData.nom ?? contact.lastName,
                                            prenom: orderData.prenom ?? contact.firstName,
                                            email: orderData.email ?? contact.email,
                                            adresse: orderData.adresse ?? account.address['addressLine'],
                                            ville: orderData.ville ?? account.address['city'],
                                            codePostal: orderData.codePostal ?? account.address['postalCode'],
                                            raisonSociale: orderData.raisonSociale ?? account.companyName,
                                            proAccountExternalId: account.externalId || '',
                                            productId: productValidateData.productId || '',
                                            siret: orderData.siret ?? account.siret,
                                            proContactExternalId: contact.externalId || '',
                                            pays: orderData.pays ?? account.address['countryCode'],
                                            numeroTelephone: orderData.numeroTelephone ?? contact.mobilePhoneNumber,
                                            salarie: orderTiers.etesVousSalarie ?? '',
                                            dateNaissance: orderTiers.dateDeNaissance ?? '',
                                            numeroDeSecuriteSocial: orderTiers.numeroDeSecuriteSociale13Chiffres ?? '',
                                            idJoueurMaxxing: productValidateData.accountMaxxingId ?? '',
                                            titre: productValidateData.productName ?? '',
                                            type: productValidateData.categoryProduct ?? '',
                                            prestataire: productValidateData.prestataire ?? '',
                                            price: productValidateData.price ?? '',
                                            lingotProduct: productValidateData.lingotProduct ?? '',
                                            clingot: productValidateData.cLingot ?? ''
                                        }
                                    };
                                    form.on('render', function () {
                                        form.getComponent('nom').setValue(orderData.nom ?? contact.lastName);
                                        form.getComponent('prenom').setValue(orderData.prenom ?? contact.firstName);
                                        form.getComponent('email').setValue(orderData.email ?? contact.email);
                                        form.getComponent('adresse').setValue(orderData.adresse ?? account.address['addressLine']);
                                        form.getComponent('ville').setValue(orderData.ville ?? account.address['city']);
                                        form.getComponent('raisonSociale').setValue(orderData.raisonSociale ?? account.companyName);
                                        form.getComponent('proAccountExternalId').setValue(account.externalId || '');
                                        form.getComponent('productId').setValue(productValidateData.productId || '');
                                        form.getComponent('siret').setValue(orderData.siret ?? account.siret);
                                        form.getComponent('proContactExternalId').setValue(contact.externalId || '');
                                        form.getComponent('pays').setValue(orderData.pays ?? account.address['countryCode']);
                                        form.getComponent('numeroTelephone').setValue(orderData.numeroTelephone ?? contact.mobilePhoneNumber);
                                        form.getComponent('salarie').setValue(orderTiers.etesVousSalarie ?? '');
                                        form.getComponent('dateNaissance').setValue(orderTiers.dateDeNaissance ?? '');
                                        form.getComponent('numeroDeSecuriteSocial').setValue(orderTiers.numeroDeSecuriteSociale13Chiffres ?? '');
                                        form.getComponent('idJoueurMaxxing').setValue(productValidateData.accountMaxxingId ?? '');
                                        form.getComponent('titre').setValue(productValidateData.productName ?? '');
                                        form.getComponent('type').setValue(productValidateData.categoryProduct ?? '');
                                        form.getComponent('prestataire').setValue(productValidateData.prestataire ?? '');
                                        form.getComponent('price').setValue(productValidateData.price ?? '');
                                        form.getComponent('lingotProduct').setValue(productValidateData.lingotProduct ?? '');
                                        form.getComponent('clingot').setValue(productValidateData.cLingot ?? '');
                                    });

                                    form.render();
                                    form.off('submit');
                                    form.once('submit', function (submission) {
                                        if (transactionId) {
                                            submission.data.transactionId = transactionId;
                                            form.getComponent('transactionId').setValue(transactionId);
                                            let tries = 0;
                                            var transactionUid = {
                                                formOrder: submission,
                                                dataWeb: transactionId
                                            };
                                            sendOrderRequest(transactionUid, orderPoppin);
                                        }
                                    });
                                    /********************************** */
                                    form.on('submitDone', function (submission) {
                                        cancelBtn.forEach(function (item) {
                                            item.addEventListener('click', function () {
                                                window.location.href = productData.equipmentPath;
                                                orderPoppin.classList.remove('is-visible');
                                            })
                                        })
                                    });
                                })
                                cancelBtn.forEach(function (item) {
                                    item.addEventListener('click', function () {
                                        orderPoppin.classList.remove('is-visible');
                                    })
                                })
                            })
                        }
                    }
                });
            })
            var currentUrl = window.location.href;
            var url = new URL(currentUrl);
            var productIdParam = url.searchParams.get("productId");
            if (productIdParam) {
                let popin = document.getElementById(`masters_order_form_${productIdParam}`);
                Formio.createForm(popin, 'https://vfhdbhdalpkaqwk.form.io/themastersorder').then(function (form) {
                    if (form) {
                        const orderPoppin = popin.closest('.orderPopin');
                        const cancelBtn = popin?.closest('.orderPopin').querySelectorAll('.cancel-order');
                        popin?.closest('.orderPopin').classList.add('is-visible');
                        var hiddenInput = orderPoppin.querySelector(".input-product").value;
                        var productData = JSON.parse(hiddenInput);
                        var currentUrl = window.location.href;
                        var url = new URL(currentUrl);
                        var productIdParam = url.searchParams.get("productId");
                        var dataToSend = {
                            productId: productIdParam,
                            lingotProduct: productData.lingotProduct
                        };

                        /************************************************* v* */
                        var productValidate = orderPoppin.querySelector(".validate-product").value;
                        var productValidateData = JSON.parse(productValidate);
                        let account = productValidateData.account;
                        let contact = productValidateData.contact;
                        let orderData = productValidateData.orderData;
                        let orderTiers = productValidateData.orderTiers;
                        var currentUrl = window.location.href;
                        var url = new URL(currentUrl);
                        var productIdParam = url.searchParams.get("productId");
                        form.submission = {
                            data: {
                                nom: orderData.nom ?? contact.lastName,
                                prenom: orderData.prenom ?? contact.firstName,
                                email: orderData.email ?? contact.email,
                                adresse: orderData.adresse ?? account.address['addressLine'],
                                ville: orderData.ville ?? account.address['city'],
                                codePostal: orderData.codePostal ?? account.address['postalCode'],
                                raisonSociale: orderData.raisonSociale ?? account.companyName,
                                proAccountExternalId: account.externalId || '',
                                productId: productIdParam ?? '',
                                siret: orderData.siret ?? account.siret,
                                proContactExternalId: contact.externalId || '',
                                pays: orderData.pays ?? account.address['countryCode'],
                                numeroTelephone: orderData.numeroTelephone ?? contact.mobilePhoneNumber,
                                salarie: orderTiers.etesVousSalarie ?? '',
                                dateNaissance: orderTiers.dateDeNaissance ?? '',
                                numeroDeSecuriteSocial: orderTiers.numeroDeSecuriteSociale13Chiffres ?? '',
                                idJoueurMaxxing: productValidateData.accountMaxxingId ?? '',
                                titre: productValidateData.productName ?? '',
                                type: productValidateData.categoryProduct ?? '',
                                prestataire: productValidateData.prestataire ?? '',
                                price: productValidateData.price ?? '',
                                lingotProduct: productValidateData.lingotProduct ?? '',
                                clingot: productValidateData.cLingot ?? ''
                            }
                        };
                        form.on('render', function () {
                            form.getComponent('nom').setValue(orderData.nom ?? contact.lastName);
                            form.getComponent('prenom').setValue(orderData.prenom ?? contact.firstName);
                            form.getComponent('email').setValue(orderData.email ?? contact.email);
                            form.getComponent('adresse').setValue(orderData.adresse ?? account.address['addressLine']);
                            form.getComponent('ville').setValue(orderData.ville ?? account.address['city']);
                            form.getComponent('raisonSociale').setValue(orderData.raisonSociale ?? account.companyName);
                            form.getComponent('proAccountExternalId').setValue(account.externalId || '');
                            form.getComponent('productId').setValue(productIdParam ?? '');
                            form.getComponent('siret').setValue(orderData.siret ?? account.siret);
                            form.getComponent('proContactExternalId').setValue(contact.externalId || '');
                            form.getComponent('pays').setValue(orderData.pays ?? account.address['countryCode']);
                            form.getComponent('numeroTelephone').setValue(orderData.numeroTelephone ?? contact.mobilePhoneNumber);
                            form.getComponent('salarie').setValue(orderTiers.etesVousSalarie ?? '');
                            form.getComponent('dateNaissance').setValue(orderTiers.dateDeNaissance ?? '');
                            form.getComponent('numeroDeSecuriteSocial').setValue(orderTiers.numeroDeSecuriteSociale13Chiffres ?? '');
                            form.getComponent('idJoueurMaxxing').setValue(productValidateData.accountMaxxingId ?? '');
                            form.getComponent('titre').setValue(productValidateData.productName ?? '');
                            form.getComponent('type').setValue(productValidateData.categoryProduct ?? '');
                            form.getComponent('prestataire').setValue(productValidateData.prestataire ?? '');
                            form.getComponent('price').setValue(productValidateData.price ?? '');
                            form.getComponent('lingotProduct').setValue(productValidateData.lingotProduct ?? '');
                            form.getComponent('clingot').setValue(productValidateData.cLingot ?? '');
                        });
                        form.render();
                        let popinProduct = popin?.closest('.orderPopin');
                        /********************************** */
                        form.on('submit', function (submission) {
                            fetch('/info-doc', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(dataToSend)
                            })
                                .then(response => response.json())
                                .then(function (data) {
                                    let dataWeb = data.webTransaction_request_response_code.transaction_uid.value;
                                    submission.data.transactionId = dataWeb;
                                    form.getComponent('transactionId').setValue(dataWeb);
                                    var transactionUid = {
                                        formOrder: submission,
                                        dataWeb: dataWeb
                                    };
                                    sendOrderRequest(transactionUid, popinProduct);
                                })
                                .catch(function (error) {
                                    console.error('There was a problem with the fetch operation:', error);
                                });
                        });

                        form.on('submitDone', function (submission) {
                            popinProduct.querySelector('.orderPopin-formTitle').style.display = "none";
                            popinProduct.querySelector('.masters_order_form').style.display = "none";
                            orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                            popinProduct.querySelector('.orderPopin-valideMsg').style.display = "block";
                        });

                        cancelBtn.forEach(function (item) {
                            item.addEventListener('click', function () {
                                if (productIdParam) {
                                    var currentUrl = window.location.href;
                                    var urlParams = new URLSearchParams(currentUrl.search);
                                    urlParams.delete('productId');
                                    var newUrl = currentUrl.split('?')[0];
                                    if (urlParams.toString() !== '') {
                                        newUrl += '?' + urlParams.toString();
                                    }
                                    window.location.href = newUrl;
                                }
                                popin?.closest('.orderPopin').classList.remove('is-visible');
                            })
                        })
                    }
                });
            }
        }
    })
}

window.onload = () => {
    setInterval(startTimer, 1000);

    function startTimer() {
        ++seconds;
    }

    const recommendationChangeDown = document.querySelector('.recommendation-change-down');
    if (recommendationChangeDown) recommendationChangeDown.addEventListener("click", changeRecommendationDown);
    const recommendationChangeUp = document.querySelector('.recommendation-change-up');
    if (recommendationChangeUp) recommendationChangeUp.addEventListener("click", changeRecommendationUp);
    const recommendationChangeConsole = document.querySelector('.recommendation-change-console');
    if (recommendationChangeConsole) recommendationChangeConsole.addEventListener("click", changeRecommendationConsole);

    const isPacAirAirTertiaire = document.querySelector('.pac-air-air-tertiaire');
    const isPacAirAirIndiv = document.querySelector('.pac-air-air-indiv');
    const isPacChauffeEauNouvelleInstallation = document.querySelector('.pac-chauffe-eau-nouvelle-installation');
    const isPacChauffeEauRemplace = document.querySelector('.pac-chauffe-eau-remplace');
    const isPacChauffeEauReference = document.querySelector('.pac-chauffe-eau-reference');
    const isPacPiscine = document.querySelector('.pac-piscine');

    if (urlParams.get('diplayMsg') !== null && urlParams.get('id') !== null) {
        let paramUrl = '?id=' + urlParams.get('id');
        if (urlParams.get('reference') !== null) {
            paramUrl += '&reference=' + urlParams.get('reference');
        }
        window.history.replaceState({}, document.title, window.location.href.split('?')[0] + paramUrl);
        document.querySelector(".successSave").style.display = "flex";
        setTimeout(() => {
            document.querySelector(".successSave").style.display = "none";
        }, 3000);
        document.querySelector('.Save-Dim').disabled = true;
    }

    const saveDim = document.querySelector('.Save-Dim');
    if (isPacAirAirIndiv && saveDim &&
        getCookie("pac_air_air_step1") !== null &&
        getCookie("pac_air_air_step2") !== null &&
        !JSON.parse(getCookie("pac_air_air_step1"))['canBeSaved'] &&
        !JSON.parse(getCookie("pac_air_air_step2"))['canBeSaved']
    ) {
        saveDim.disabled = true;
    } else if (isPacAirAirTertiaire && saveDim && getCookie("pac_air_tertiaire_step1") !== null &&
        !JSON.parse(getCookie("pac_air_tertiaire_step1"))['canBeSaved']) {
        saveDim.disabled = true;
    } else if (isPacChauffeEauNouvelleInstallation && saveDim && getCookie("new_installation") !== null &&
        !JSON.parse(getCookie("new_installation"))['canBeSaved']) {
        saveDim.disabled = true;
    } else if (isPacChauffeEauRemplace && saveDim && getCookie("replace_chauffe-eau") !== null &&
        !JSON.parse(getCookie("replace_chauffe-eau"))['canBeSaved']) {
        saveDim.disabled = true;
    } else if (isPacChauffeEauReference && saveDim && getCookie("reference_chauffe_eau") !== null &&
        !JSON.parse(getCookie("reference_chauffe_eau"))['canBeSaved']) {
        saveDim.disabled = true;
    } else if (isPacPiscine && saveDim && getCookie("pac_piscine_step1") !== null &&
        !JSON.parse(getCookie("pac_piscine_step1"))['canBeSaved']) {
        saveDim.disabled = true;
    }

    var rememberCookie = getCookie('remember_sso_user');
    var sessionCookie = getCookie('sessionExpiredPopPin');
    var shared_domain = window.location.hostname.replace("www.", "");
    if (rememberCookie !== null && sessionCookie == null) {
        setCookie('sessionExpiredPopPin', rememberCookie, shared_domain, '/', 400);
        deleteCookie('remember_sso_user', shared_domain, '/');
    }

    if (sessionCookie !== null) {
        if (parseInt(sessionCookie) < getTimestampInSeconds()) {
            displaySessionPopin();
        } else {
            const header = document.querySelector('header');
            // Testing if the user is already connected (if the header contains the class "user-connected")
            if (header !== null && !header.classList.contains('user-connected')) {
                displaySessionPopin();
            }
        }
    }

    let removeSessionButton = document.querySelector('#removeSessionButton');
    if (removeSessionButton) {
        removeSessionButton.addEventListener('click', function (e) {
            e.preventDefault();
            deleteCookie('sessionExpiredPopPin', shared_domain, '/');
            deleteCookie('remember_sso_user', shared_domain, '/');
            window.location.href = removeSessionButton.href;
        })
    }

    let logoutButton = document.querySelector('.logout_button');
    if (logoutButton) {
        logoutButton.addEventListener('click', function (e) {
            e.preventDefault();
            deleteCookie('sessionExpiredPopPin', shared_domain, '/');
            deleteCookie('remember_sso_user', shared_domain, '/');
            window.location.href = logoutButton.href;
        })
    }
}

function displaySessionPopin() {
    var shared_domain = window.location.hostname.replace("www.", "");
    let sessionExpiredPopPin = document.getElementById('sessionExpiredModal');
    if (sessionExpiredPopPin) {
        sessionExpiredPopPin.classList.add('show');

        sessionExpiredPopPin.querySelectorAll('.close').forEach((item) => {
            item.addEventListener('click', function () {
                sessionExpiredPopPin.classList.remove('show');
                deleteCookie('sessionExpiredPopPin', shared_domain, '/');
                deleteCookie('remember_sso_user', shared_domain, '/');
            })
        })
    }
}

function getTimestampInSeconds() {
    return Math.floor(Date.now() / 1000)
}

// master logo position

const disconnectedMasterLogo = document.querySelector('.FullHeaderTop-item--horizonLogo.desactivated-account');
const connectedAccount = document.querySelector('.FullHeaderTop-link--withIcon');

if (disconnectedMasterLogo) {
    let rightOffset = connectedAccount.offsetWidth + document.querySelector('.FullHeaderTop-link--sites').offsetHeight;
    disconnectedMasterLogo.style.right = rightOffset + 'px';
}

let isPacAirAir = document.querySelector('#BlockProjectDim--wrapper.BlockProjectDimAirAir--wrapper');
let isPoolPac = document.querySelector('#BlockProjectDim--wrapper.BlockProjectDimPool--wrapper');
let isPacChauffeEau = document.querySelector('#BlockProjectChauffe-eau--wrapper');

if (isPacAirAir) {

    document.querySelector('#BlockProjectDim--wrapper').querySelectorAll('.BlockProjectDim').forEach(function (item) {
        const editLink = item.querySelector('.BlockProjectDim--actions .edit');
        const syntheseLink = item.querySelector('.BlockProjectDim--response .synthese');
        const responseLink = item.querySelector('.BlockProjectDim--response .response');

        editLink.addEventListener('click', function () {
            const idItem = editLink.dataset.id;
            const linkItem = editLink.dataset.link;
            const type = editLink.dataset.type;

            if (type == "appartement") {
                updateCookie('Individuel', idItem, ["pac_air_air_step1", "pac_air_air_step2"], '/pac-air-air-get-project-by-id/', linkItem);
            } else {
                updateCookie('Tertiaire', idItem, ["pac_air_tertiaire_step1"], '/pac-air-air-get-project-by-id/', linkItem);
            }
        })

        syntheseLink.addEventListener('click', function () {
            const idItem = syntheseLink.dataset.id;
            const linkItem = syntheseLink.dataset.link;
            const type = syntheseLink.dataset.type;

            if (type == "appartement") {
                updateCookie('Individuel', idItem, ["pac_air_air_step1", "pac_air_air_step2"], '/pac-air-air-get-project-by-id/', linkItem);
            } else {
                updateCookie('Tertiaire', idItem, ["pac_air_tertiaire_step1"], '/pac-air-air-get-project-by-id/', linkItem);
            }
        })

        if (responseLink) {
            responseLink.addEventListener('click', function () {
                const idItem = responseLink.dataset.id;
                const linkItem = responseLink.dataset.link;
                const type = responseLink.dataset.type;

                if (type == "appartement") {
                    updateCookie('Individuel', idItem, ["pac_air_air_step1", "pac_air_air_step2"], '/pac-air-air-get-project-by-id/', linkItem);
                } else {
                    updateCookie('Tertiaire', idItem, ["pac_air_tertiaire_step1"], '/pac-air-air-get-project-by-id/', linkItem);
                }
            })
        }
    })
} else if (isPoolPac) {

    document.querySelector('#BlockProjectDim--wrapper').querySelectorAll('.sizing-pool').forEach(function (item) {
        const linkId = item.querySelector('.BlockProjectDim--actions .edit');
        const responseLink = item.querySelector('.BlockProjectDim--response .response');

        linkId.addEventListener('click', function () {
            const idItem = linkId.dataset.id;
            const linkItem = linkId.dataset.link;
            updateCookie('Piscine', idItem, ["pac_piscine_step1"], '/pac-pool-get-project-by-id/', linkItem);
        })

        responseLink.addEventListener('click', function () {
            const idItem = responseLink.dataset.id;
            const linkItem = responseLink.dataset.link;
            updateCookie('Piscine', idItem, ["pac_piscine_step1"], '/pac-pool-get-project-by-id/', linkItem);
        })
    })
} else if (isPacChauffeEau) {
    document.querySelector('#BlockProjectChauffe-eau--wrapper').querySelectorAll('.BlockProjectChauffe-eau').forEach(function (item) {
        const response = item.querySelector('.BlockProjectChauffe-eau--response .response');
        const synthese = item.querySelector('.BlockProjectChauffe-eau--synthese .synthese');

        response.addEventListener('click', function () {
            const idItem = response.dataset.id;
            const linkItem = response.dataset.link;
            const type = response.dataset.type;

            if (type == "nouvelle_installation") {
                updateCookie('Chauffe-eau-nouvelle-installation', idItem, ["new_installation"], '/pac-chauffe-eau-get-project-by-id/', linkItem)
            } else if (type == "projet_remplacement") {
                updateCookie('Chauffe-eau-remplacement', idItem, ["replace_chauffe-eau"], '/pac-chauffe-eau-get-project-by-id/', linkItem);
            } else {
                updateCookie('Chauffe-eau-nouvelle-reference', idItem, ["reference_chauffe_eau"], '/pac-chauffe-eau-get-project-by-id/', linkItem);
            }
        })

        if (synthese) {
            synthese.addEventListener('click', function () {
                const idItem = response.dataset.id;
                const linkItem = response.dataset.link;
                const type = response.dataset.type;

                if (type == 'projet_nouvelle_reference') {
                    updateCookie('Chauffe-eau-nouvelle-reference', idItem, ["reference_chauffe_eau"], '/pac-chauffe-eau-get-project-by-id/', linkItem);
                }
            })
        }
    })
}

function updateCookie(type, id, cookiesNames, uri, linkItem) {
    const url = uri + id + '/' + type;

    fetch(url, {
        method: 'POST'
    })
        .then(response => {
            if (response.ok) {
                response.json().then((data) => {

                    for (let i = 0; i < cookiesNames.length; i++) {
                        let object = data[cookiesNames[i]];
                        object['canBeSaved'] = false;
                        object['idDim'] = id;

                        let objectString = JSON.stringify(object);
                        document.cookie = cookiesNames[i] + "=" + objectString + "; expires=" + (new Date(Date.now() + 86400 * 1000)).toUTCString();
                        window.location.replace(apiUrl + linkItem + "?id=" + id)
                    }
                });
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

function addOrDeleteClassOnClass(className, modifiedClasse, addClass = true) {
    var domElements = document.querySelectorAll(className);
    if (domElements.length > 0) {
        domElements.forEach(function (domElement) {
            if (addClass) {
                domElement.classList.add(modifiedClasse);
            } else {
                domElement.classList.remove(modifiedClasse);
            }
        });
    }
}

function updateRef(className) {
    let textRef = 'UE, ' + document.querySelector('.recommendation-ue').querySelector('.ProjectRecommended--ref-container p').textContent;
    var domElements = document.querySelectorAll(className);
    for (let i = 0; i < domElements.length; i++) {
        textRef += ' | ';
        textRef += 'UI ' + (i + 1) + ', ' + domElements[i].querySelector('.ProjectRecommended--ref-container p').textContent
    }
    document.querySelector('.ProjectResponse--references').querySelector('.ProjectResponse--content-value').textContent = textRef;
}

function changeRecommendationConsole() {
    const recommendationChangeConsole = document.querySelector('.recommendation-change-console');
    recommendationChangeConsole.disabled = true;

    addOrDeleteClassOnClass('.recommendation-down', 'd-none', true);
    addOrDeleteClassOnClass('.recommendation', 'd-none', true);
    addOrDeleteClassOnClass('.recommendation-up', 'd-none', true);
    addOrDeleteClassOnClass('.recommendation-console', 'd-none', false);

    updateRef('.recommendation-console');
}

function changeRecommendationDown() {
    const recommendationChangeDown = document.querySelector('.recommendation-change-down');
    const recommendationChangeUp = document.querySelector('.recommendation-change-up');
    const recommendationChangeConsole = document.querySelector('.recommendation-change-console');
    if (!document.querySelectorAll('.recommendation-change-console-no').length == 1) {
        recommendationChangeConsole.disabled = false;
    }

    addOrDeleteClassOnClass('.recommendation-console', 'd-none', true);
    if (recommendationChangeUp.getAttribute('data-button-selected') === 'true') {
        recommendationChangeUp.setAttribute('data-button-selected', 'false');
        addOrDeleteClassOnClass('.recommendation-up', 'd-none', true);
        addOrDeleteClassOnClass('.recommendation', 'd-none', false);
        updateRef('.recommendation');
        if (document.querySelectorAll('.recommendation-change-down-no').length == 1) {
            document.querySelector('.recommendation-change-down').disabled = true;
        }
        document.querySelector('.recommendation-change-up').disabled = false;
    } else {
        recommendationChangeDown.setAttribute('data-button-selected', 'true');
        addOrDeleteClassOnClass('.recommendation', 'd-none', true);
        addOrDeleteClassOnClass('.recommendation-down', 'd-none', false);
        updateRef('.recommendation-down');
        document.querySelector('.recommendation-change-down').disabled = true;
        document.querySelector('.recommendation-change-up').disabled = false;
    }
}

function changeRecommendationUp() {
    const recommendationChangeDown = document.querySelector('.recommendation-change-down');
    const recommendationChangeUp = document.querySelector('.recommendation-change-up');
    const recommendationChangeConsole = document.querySelector('.recommendation-change-console');
    if (!document.querySelectorAll('.recommendation-change-console-no').length == 1) {
        recommendationChangeConsole.disabled = false;
    }

    addOrDeleteClassOnClass('.recommendation-console', 'd-none', true);
    if (recommendationChangeDown.getAttribute('data-button-selected') === 'true') {
        recommendationChangeDown.setAttribute('data-button-selected', 'false');
        addOrDeleteClassOnClass('.recommendation-down', 'd-none', true);
        addOrDeleteClassOnClass('.recommendation', 'd-none', false);
        updateRef('.recommendation');
        if (document.querySelectorAll('.recommendation-change-up-no').length == 1) {
            document.querySelector('.recommendation-change-up').disabled = true;
        }
        document.querySelector('.recommendation-change-down').disabled = false;
    } else {
        recommendationChangeUp.setAttribute('data-button-selected', 'true');
        addOrDeleteClassOnClass('.recommendation', 'd-none', true);
        addOrDeleteClassOnClass('.recommendation-up', 'd-none', false);
        updateRef('.recommendation-up');
        document.querySelector('.recommendation-change-up').disabled = true;
        document.querySelector('.recommendation-change-down').disabled = false;
    }
}

// Main.jss
// Critical JS (used to open popups
(function () {
    const elementsPopup = document.querySelectorAll('[data-popup]')
    let linksPopup = document.querySelectorAll('[data-popup-link]')
    linksPopup = Array.from(linksPopup) // Converting NodeList to Array for filter() below

    let popups = []

    elementsPopup.forEach(elementPopup => {
        if (elementPopup.dataset.popup == 'login') {
            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'login'
            })
            window.popupLogin = new PopupLogin(elementPopup, links);
            popups.push(window.popupLogin)
        } else if (elementPopup.dataset.popup == 'order') {
            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'order'
            })

            popups.push(new PopupOrder(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'search') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'search'
            })
            popups.push(new PopupSearch(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'delete') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == elementPopup.dataset.popupId
            })
            popups.push(new PopupDelete(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'save') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == elementPopup.dataset.popupId
            })
            popups.push(new PopupSave(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'gainable') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'gainable'
            })
            popups.push(new PopupGainable(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'shareMail') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'shareMail'
            })
            popups.push(new PopupMail(elementPopup, links))
        }
    })

    const loggedInCookie = utilities.getCookie('is_logged_in')
    if (!loggedInCookie) {
        document.querySelectorAll('.CartCaracs-checkoutBtn button').forEach(checkoutButton => {
            checkoutButton.setAttribute('data-popup-link', "login")
            checkoutButton.addEventListener('click', function (event) {
                window.popupLogin.open()
            })
        })
    }

    // Initialization of login popups on some elements which require the user to be logged in.
    initAdvancedLoginPopup();

    searchSpellcheck()
}());

function searchSpellcheck() {
    const searchSpellCheck = document.querySelector('[data-suggestion]')
    if (searchSpellCheck && searchSpellCheck !== null) {
        searchSpellCheck.addEventListener('click', event => {
            const url = new URL(window.location.href)
            url.searchParams.set('search_form[search_text]', searchSpellCheck.textContent)
            window.location.href = url.href;
        })
    }
}

function openPopupOnGuideProFormSubmit(elementPopup) {
    const form = document.getElementById('formGuidePro');
    if (form.dataset.formResult === 'success') {

    } else if (form.dataset.formResult === 'fail') {
        openPopup(elementPopup)
    }
}

let refreshLink = document.getElementById('rafraichir');
if (refreshLink != null && refreshLink.value == '') {
    window.addEventListener('beforeunload', function (event) {
        if (seconds < 5) {
            event.preventDefault();
            return false;
        }
        return true;
    });
}
let dashboardAvailabilityProduct = document.querySelector('.Dashboard--availability-product');
let searchInput = document.getElementById('availability_product_form_availability_product');
let search_list = document.getElementById('search_list');
let btn_submit = document.getElementById('availability_product_form_submit');
let selectedElement = null;

if (dashboardAvailabilityProduct) {
    let openAccordion = dashboardAvailabilityProduct.querySelector('.accordion-header .accordion-button');

    searchInput.setAttribute('autocomplete', 'off');

    if (searchInput.value.length === 0) {
        document.querySelector('.message-result').style.display = 'none';
    }

    if (searchInput.value.length !== 0) {
        btn_submit.removeAttribute('disabled');
    }

    searchInput.addEventListener('keyup', function (e) {
        btn_submit.removeAttribute('disabled');

        if (searchInput.value.length === 0) {
            btn_submit.setAttribute('disabled', 'disabled');
            document.querySelector('.table-wrapper').style.display = "none";

            const elementsToHide = document.querySelectorAll('.table-search, .results-search, .message-result');

            elementsToHide.forEach(function (element) {
                element.style.display = 'none';
            });
        }

        search_list.innerHTML = '';


        if (searchInput.value.length >= 3) {

            let dataToSend = {
                searchInput: searchInput.value
            };
            fetch('/account/availability-p/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                search_list.innerHTML = '';
                let matchedData = data.matchedData;
                matchedData.forEach(element => {
                    const paragraph = document.createElement('p');
                    const firstThreeLetters = element.substring(0, 3);
                    const spanElement = document.createElement('span');
                    spanElement.classList.add("firstThreeLetters");
                    spanElement.textContent = firstThreeLetters;
                    paragraph.textContent = element.substring(3);
                    paragraph.prepend(spanElement);
                    search_list.appendChild(paragraph);
                    search_list.style.display = "block";

                    paragraph.addEventListener('click', function () {

                        if (selectedElement) {
                            selectedElement.classList.remove('selected');
                        }

                        paragraph.classList.add('selected');
                        selectedElement = paragraph;
                        searchInput.value = element;

                        btn_submit.click();
                    });
                });
            });
        } else {
            search_list.style.display = "none";
        }

    });

    openAccordion.addEventListener('click', function () {
        openAccordion.querySelector('.icon').removeAttribute('class');

        if (openAccordion.classList.contains('collapsed')) {
            openAccordion.querySelector('svg').setAttribute('class', 'icon icon-chevron-down');
            openAccordion.querySelector('svg use').setAttribute('xlink:href', '/bundles/thermorprosite/dist/icons.svg#icon-chevron-down');
        } else {
            openAccordion.querySelector('svg').setAttribute('class', 'icon icon-chevron');
            openAccordion.querySelector('svg use').setAttribute('xlink:href', '/bundles/thermorprosite/dist/icons.svg#icon-chevron');
        }
    })
}

const masterMobileHeader = document.querySelector('.FullHeaderTop-item--horizonLogo-mobile');

if (masterMobileHeader && window.getComputedStyle(masterMobileHeader).display !== "none") {
    document.querySelector('.Footer').style.marginBottom = "57px";
}


if (document.querySelectorAll('.EngagementProgramHeader--status')) {
    document.querySelectorAll('.EngagementProgramHeader--status').forEach((item) => {
        item.addEventListener('click', function () {
            const infoBullWrapper = item.closest('.FullHeaderTop-item').querySelector('.infoBull--wrapper');
            infoBullWrapper.classList.toggle("show");
        })
    });
}

let btnShowHistory = document.querySelector('.blockMonProfilAventure-button button');
if (btnShowHistory) {
    let tableBody = document.querySelector('.blockMonProfilAventure .rwd-table2 tbody');
    let loader = document.querySelector('.blockMonProfilAventure--content .loader');
    let adventureBlock = document.querySelector('.blockMonProfilAventure--content');
    const rows = tableBody.querySelectorAll('tr');
    let rowCount = 6;

    function displayRows() {
        for (let i = 0; i < rows.length; i++) {
            if (i < rowCount) {
                rows[i].style.display = '';
            } else {
                rows[i].style.display = 'none';
            }
        }
    }

    displayRows();
    adventureBlock.addEventListener('scroll', function () {
        let scrollTop = adventureBlock.scrollTop;
        loader.style.top = scrollTop + 'px';
    });


    btnShowHistory.addEventListener('click', function () {
        loader.style.display = "block";
        setTimeout(() => {
            loader.style.display = "none";
            rowCount += 5;
            displayRows();
        }, 1000);

    })
}

let btnShowOrderHistory = document.querySelector('.blockMonProfilEquipement-button button');
if (btnShowOrderHistory) {
    let tableOrderBody = document.querySelector('.blockMonProfilEquipement .rwd-table tbody');
    let loaderOrder = document.querySelector('.blockMonProfilEquipement .loader');
    let equipmentBlock = document.querySelector('.blockMonProfilEquipement--content');
    const rows = tableOrderBody.querySelectorAll('tr');
    let rowCount = 10;

    function displayRowsOrders() {
        for (let i = 0; i < rows.length; i++) {
            if (i < rowCount) {
                rows[i].style.display = '';
            } else {
                rows[i].style.display = 'none';
            }
        }
    }

    displayRowsOrders();
    equipmentBlock.addEventListener('scroll', function () {
        let scrollTop = equipmentBlock.scrollTop;
        loaderOrder.style.top = scrollTop + 'px';
    });


    btnShowOrderHistory.addEventListener('click', function () {
        loaderOrder.style.display = "block";
        setTimeout(() => {
            loaderOrder.style.display = "none";
            rowCount += 10;
            displayRowsOrders();
        }, 1000);

    })
}

(function () {
    let $popupButtons = $('button[data-popup-link="search"], #hero_search_form_search_text, #mobile_search_form_search_text');

// Add click event listener to the button
    $popupButtons.on('click', function () {

        $.ajax({
            url: '/search-popup',
            type: 'GET',
            beforeSend: function (data) {
                let loaderHtml = '<div class="loadingImg"><img src="/bundles/thermorprosite/images/animation/thermor-animation-chargement.svg" alt="chargement"/></div>';

                $('.PopupSearch').find('.PopupSearch--wrapper').html(loaderHtml);
            },
            success: function (data) {
                $('.PopupSearch').find('.PopupSearch--wrapper').html(data);
                fetch('/data-modal', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        const pagination = document.querySelector('.pagination-popin');
                        const dataLength = data?.length;

                        if (dataLength <= 1) {
                            if (pagination) {
                                pagination.style.display = 'none';
                            }
                        } else {
                            if (pagination) {
                                pagination.style.display = 'block';
                            }
                        }

                        let paginationPopin = document.querySelectorAll('.pagination-popin');
                        paginationPopin.forEach((paginationPopinItem, index) => {
                            let pageRecent = paginationPopinItem.querySelector('.currentPage');
                            let currentPage = parseInt(pageRecent?.textContent);
                            let nextBtn = paginationPopinItem.querySelector('.next');
                            let previousBtn = paginationPopinItem.querySelector('.previous');

                            if (nextBtn) {

                                nextBtn.addEventListener('click', function (event) {
                                    event.preventDefault();

                                    if (currentPage < dataLength) {
                                        previousBtn.classList.add('active')
                                        currentPage++;
                                        updatePageContent(currentPage);
                                    }
                                    if (currentPage === dataLength) {
                                        event.target.classList.remove('active')
                                    }
                                });
                            }
                            if (previousBtn) {
                                previousBtn.addEventListener('click', function (event) {
                                    event.preventDefault();
                                    if (currentPage > 1) {
                                        nextBtn.classList.add('active')
                                        currentPage--;
                                        updatePageContent(currentPage);
                                    }
                                    if (currentPage === 1) {
                                        event.target.classList.remove('active')
                                    }
                                });
                            }
                        })

                        function updatePageContent(currentPage) {
                            const paginationPopinItems = document.querySelectorAll('.pagination-popin');
                            const dataLength = data.length;

                            paginationPopinItems.forEach(paginationPopinItem => {
                                let pageRecent = paginationPopinItem.querySelector('.currentPage');

                                if (pageRecent) {
                                    pageRecent.textContent = currentPage;
                                }

                                const popupParent = paginationPopinItem.closest('.PopupSearch-promote');
                                let title = popupParent.querySelector('.PopupSearch-promote--title');
                                let desc = popupParent.querySelector('.PopupSearch-promote--desc');
                                let link = popupParent.querySelector('.PopupSearch-promote--link');
                                let img = popupParent.querySelector('.PopupSearch-promote--img img');
                                const pageIndex = currentPage - 1;
                                const pageData = data[pageIndex];
                                if (pageData) {
                                    title ? title.textContent = pageData.title || '' : '';
                                    desc ? desc.innerHTML = pageData.description || '' : '';
                                    link ? link.textContent = pageData.call_to_action_text || '' : '';
                                    link?.setAttribute('href', pageData.target_url || '');
                                    img?.setAttribute('src', pageData.image || '');
                                }

                                // Ajouter la gestion des boutons précédents et suivants si nécessaire
                                let previousBtn = paginationPopinItem.querySelector('.previousButton');
                                let nextBtn = paginationPopinItem.querySelector('.nextButton');

                                if (previousBtn) {
                                    previousBtn.disabled = currentPage === 1;
                                }
                                if (nextBtn) {
                                    nextBtn.disabled = currentPage === dataLength;
                                }
                            })
                        }

                        let currentPage = 1;
                        updatePageContent(currentPage);
                    })
                    .catch(error => {
                        console.error(error);
                    });

                let popinSearch = document.querySelector('.PopupSearch');

                let loader = document.querySelector('.PopupSearch-resultats .loader');
                if (loader) {
                    loader.style.display = "none";
                }
                const inputSearch = document.querySelector('#hero_search_form_search_text');

                if (inputSearch && popinSearch) {
                    const popup = new Popup(popinSearch, [inputSearch], "focus");
                }
                if (popinSearch) {
                    let searchAlgolia = document.getElementById('header_search_form_search_text');
                    let ulSuggestionOutils = document.querySelector('#suggestion-outils');
                    let ulOutils = document.querySelector('#search-outils');
                    if (ulOutils) {
                        ulOutils.style.display = 'none';
                    }
                    let fetchTimeOutSuggestion = 0;
                    searchAlgolia.addEventListener('input', function (e) {
                        clearTimeout(fetchTimeOutSuggestion);
                        let ValueInput = this.value;
                        let ValueInputToSend = {
                            ValueInput: ValueInput
                        };
                        fetchTimeOutSuggestion = setTimeout(() => {
                            fetch('/suggestion', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(ValueInputToSend)
                            })
                                .then(response => response.json())
                                .then(data => {
                                    /**********************************************************/

                                    let suggestionPopin = document.querySelectorAll('#suggestion-popin li');
                                    let UlsuggestionPopin = document.querySelector('#suggestion-popin');

                                    UlsuggestionPopin.innerHTML = "";
                                    data.termSuggestion.forEach((term, index) => {
                                        if (UlsuggestionPopin) {
                                            let newListItem = document.createElement('li');
                                            newListItem.classList.add('search-record');
                                            newListItem.innerHTML = '<a href="' + data.routeResult + '?search=' + term + '">' + term + '</a>';
                                            newListItem.style.visibility = 'visible';
                                            const elementUl = document.getElementById("suggestion-popin");
                                            if (elementUl) {
                                                const numberOfLiElements = elementUl.getElementsByTagName("li").length;
                                                if (numberOfLiElements === data.termSuggestion.length) {
                                                    if (numberOfLiElements > 0) {
                                                        const lastLiElement = elementUl.getElementsByTagName("li")[numberOfLiElements - 1];
                                                        lastLiElement.style.borderBottom = 'none';
                                                    }
                                                }
                                            }
                                            UlsuggestionPopin.appendChild(newListItem);
                                            suggestionPopin.forEach(element => {
                                                element.style.visibility = 'hidden';
                                            });
                                        }
                                    });

                                    /****************************************************************/
                                    const suggestionSearch = data.suggestionSearch ? data.suggestionSearch.slice(0, 3) : [];
                                    ulOutils.innerHTML = "";
                                    if (suggestionSearch.length === 0) {
                                        if (ulSuggestionOutils) ulSuggestionOutils.style.display = 'block';
                                        if (ulOutils) ulOutils.style.display = 'none';
                                    } else {
                                        suggestionSearch.forEach((item, index) => {
                                            let newListItem = document.createElement('li');
                                            newListItem.classList.add('search-record');
                                            newListItem.innerHTML = '<a href="' + data.routeResult + '?search=' + item.title + '">' + item.title + '</a>';
                                            newListItem.style.visibility = 'visible';
                                            ulOutils.appendChild(newListItem);
                                        });
                                        if (ulOutils) ulOutils.style.display = 'block';
                                        if (ulSuggestionOutils) ulSuggestionOutils.style.display = 'none';
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                        }, 1000);

                    });

                    let PopupResult = document.querySelectorAll('.PopupSearch-resultats');

                    PopupResult.forEach(function (itemPopupResult) {
                        let result = itemPopupResult.querySelector('.PopupSearch-resultats--titre');
                        let seeMoreResult = itemPopupResult.querySelector('.see-more-result');
                        result.style.display = 'none';
                        seeMoreResult.style.display = 'none';
                        let fetchTimeOut = 0;
                        searchAlgolia.addEventListener('input', function () {
                            clearTimeout(fetchTimeOut);
                            let descResult = document.querySelector('.PopupSearch-resultats-desc');
                            let ulElement = result.querySelector('ul');
                            let query = this.value;
                            let dataToSend = {
                                titleSearchable: query
                            };

                            loader.style.display = "block";
                            if (loader) {
                                loader.style.borderTop = '6px solid #db3434';
                            }
                            fetchTimeOut = setTimeout(() => {
                                fetch('/content-algolia-search', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(dataToSend)
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        if (query.length === 0) {
                                            descResult.style.display = 'block';
                                            result.style.display = 'none';
                                            seeMoreResult.style.display = 'none';
                                        } else {
                                            descResult.style.display = 'none';
                                            result.style.display = 'block';
                                            seeMoreResult.style.display = 'block';

                                            let counter = 0;
                                            let html = '';
                                            data.forEach(function (item) {
                                                if (counter < data.length) {
                                                    html += `<li><a href="${item.url}"><span class="tag">${item.contentType}</span><span class="text">${item.title}</span></a></li>`;
                                                    counter++;
                                                }
                                            });
                                            ulElement.innerHTML = html;
                                        }
                                        loader.style.display = "none";
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                            }, 1000);


                        });
                    });


                }

                let hiddenValueInput = document.getElementById('searchResults-items');

                if (hiddenValueInput) {
                    let searchPageResult = hiddenValueInput.value;
                    let currentPath = window.location.pathname;
                    let dataId = null;

                    if (currentPath === searchPageResult) {
                        let searchT = document.querySelector('#search_form_search_text');
                        let searchText = searchT.value ?? null;
                        fetch('/data-modal', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        })
                            .then(response => response.json())
                            .then(data => {
                                const paginationHighlighted = document.querySelector('.pagination-highlighted');
                                const dataLength = data.length;

                                if (dataLength <= 1) {
                                    if (paginationHighlighted) paginationHighlighted.style.display = 'none';
                                } else {
                                    paginationHighlighted.style.display = 'block';
                                }

                                let pageRecentHighlighted = document.querySelector('.pagination-highlighted .currentPage-highlighted');
                                let nextBtnHighlighted = document.querySelector('.pagination-highlighted .next-highlighted');
                                let previousBtnHighlighted = document.querySelector('.pagination-highlighted .previous-highlighted');
                                let titleHighlighted = document.querySelector('.searchResults-article-title');
                                let descHighlighted = document.querySelector('.searchResults-article-content p');
                                let linkHighlighted = document.querySelector('.searchResults-article-cta a');
                                let imgHighlighted = document.querySelector('.searchResults-article-img img');

                                nextBtnHighlighted.addEventListener('click', function (event) {
                                    event.preventDefault();
                                    if (parseInt(document.querySelector('.nbPages-highlighted').textContent) > parseInt(pageRecentHighlighted.textContent)) {
                                        if (parseInt(pageRecentHighlighted.textContent) + 1 === parseInt(document.querySelector('.nbPages-highlighted').textContent)) {
                                            event.target.classList.add('disabled');
                                        }
                                        let btnprev = event.target.closest('.paginate-highlighted').querySelector('.previous-highlighted');

                                        btnprev.classList.remove('disabled');
                                        pageRecentHighlighted.textContent = parseInt(pageRecentHighlighted.textContent) + 1;
                                        const pageData = data[parseInt(pageRecentHighlighted.textContent) - 1];
                                        if (pageData) {
                                            if (titleHighlighted) titleHighlighted.textContent = pageData.title || '';
                                            if (descHighlighted) descHighlighted.textContent = pageData.description || '';
                                            if (linkHighlighted) linkHighlighted.textContent = pageData.call_to_action_text || '';
                                            if (linkHighlighted) linkHighlighted.setAttribute('href', pageData.target_url || '');
                                            imgHighlighted.setAttribute('src', pageData.image || '');
                                        }
                                    }
                                });

                                previousBtnHighlighted.addEventListener('click', function (event) {
                                    event.preventDefault();
                                    if (parseInt(pageRecentHighlighted.textContent) > 1) {

                                        if (parseInt(pageRecentHighlighted.textContent) - 1 === 1) {
                                            event.target.classList.add('disabled');
                                        }
                                        let btnnext = event.target.closest('.paginate-highlighted').querySelector('.next-highlighted');

                                        btnnext.classList.remove('disabled');
                                        pageRecentHighlighted.textContent = parseInt(pageRecentHighlighted.textContent) - 1;
                                        const pageData = data[parseInt(pageRecentHighlighted.textContent) - 1];
                                        if (pageData) {
                                            titleHighlighted.textContent = pageData.title || '';
                                            if (descHighlighted) descHighlighted.textContent = pageData.description || '';
                                            if (linkHighlighted) linkHighlighted.textContent = pageData.call_to_action_text || '';
                                            if (linkHighlighted) linkHighlighted.setAttribute('href', pageData.target_url || '');
                                            imgHighlighted.setAttribute('src', pageData.image || '');
                                        }
                                    }
                                });
                            })
                            .catch(error => {
                                console.error(error);
                            });


                        let nextList = document.querySelectorAll('.next-list');
                        let previousList = document.querySelectorAll('.previous-list');

                        nextList.forEach(function (element) {
                            element.addEventListener('click', function (event) {
                                event.preventDefault();
                                let dataId = element.closest('.pagination-list').getAttribute('data-id');
                                let pageFollowing = element.closest('.pagination-list').querySelector('.currentPage-list');
                                let previous = element.closest('.pagination-list').querySelector('.previous-list');
                                let nbPagesList = element.closest('.pagination-list').querySelector('.nbPages-list');
                                previous.style.pointerEvents = 'auto';
                                if (parseInt(pageFollowing.textContent.trim()) + 1 > 1) {
                                    previous.classList.add('active')
                                }

                                if (parseInt(pageFollowing.textContent.trim()) + 1 === parseInt(nbPagesList.textContent.trim())) {
                                    element.classList.remove('active')
                                } else {
                                    element.classList.add('active')
                                }

                                if (parseInt(pageFollowing.textContent.trim()) >= 1 && parseInt(nbPagesList.textContent.trim()) > parseInt(pageFollowing.textContent.trim())) {
                                    fetchData(dataId, pageFollowing, element);
                                }

                            });
                        });

                        previousList.forEach(function (element) {
                            element.addEventListener('click', function (event) {
                                //element.classList.remove('active')
                                event.preventDefault();
                                let dataId = element.closest('.pagination-list').getAttribute('data-id');
                                let pageFollowing = element.closest('.pagination-list').querySelector('.currentPage-list');
                                let nbPagesList = element.closest('.pagination-list').querySelector('.next-list');
                                if (parseInt(pageFollowing.textContent.trim()) - 1 > 1) {
                                    nbPagesList.classList.add('active')
                                    fetchData(dataId, pageFollowing, element);
                                } else if (parseInt(pageFollowing.textContent.trim()) - 1 === 1) {
                                    element.style.pointerEvents = 'none';
                                    element.classList.remove('active')
                                    fetchData(dataId, pageFollowing, element);
                                }
                            });
                        });

                    }
                }
            }
        });
    });
    if (location.hash === '#search') {
        $popupButtons.first().trigger('click');
    }
})();


let nextList = document.querySelectorAll('.next-list');
let previousList = document.querySelectorAll('.previous-list');

nextList.forEach(function (element) {

    element.addEventListener('click', function (event) {
        event.preventDefault();
        let dataId = element.closest('.pagination-list').getAttribute('data-id');
        let pageFollowing = element.closest('.pagination-list').querySelector('.currentPage-list');
        let previous = element.closest('.pagination-list').querySelector('.previous-list');
        let nbPagesList = element.closest('.pagination-list').querySelector('.nbPages-list');
        previous.style.pointerEvents = 'auto';
        if (parseInt(pageFollowing.textContent.trim()) + 1 > 1) {
            previous.classList.add('active')
        }

        if (parseInt(pageFollowing.textContent.trim()) + 1 === parseInt(nbPagesList.textContent.trim())) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }

        if (parseInt(pageFollowing.textContent.trim()) >= 1 && parseInt(nbPagesList.textContent.trim()) > parseInt(pageFollowing.textContent.trim())) {
            fetchData(dataId, pageFollowing, element);
        }

    });
});

previousList.forEach(function (element) {
    element.addEventListener('click', function (event) {
        //element.classList.remove('active')
        event.preventDefault();
        let dataId = element.closest('.pagination-list').getAttribute('data-id');
        let pageFollowing = element.closest('.pagination-list').querySelector('.currentPage-list');
        let nbPagesList = element.closest('.pagination-list').querySelector('.next-list');
        if (parseInt(pageFollowing.textContent.trim()) - 1 > 1) {
            nbPagesList.classList.add('active')
            fetchData(dataId, pageFollowing, element);
        } else if (parseInt(pageFollowing.textContent.trim()) - 1 === 1) {
            element.style.pointerEvents = 'none';
            element.classList.remove('active')
            fetchData(dataId, pageFollowing, element);
        }
    });
});

function fetchData(dataId, pageFollowing, element) {
    const urlParams = new URLSearchParams(window.location.search);
    const searchText = urlParams.get('search');

    let dataSearch = {
        searchText: searchText,
        dataId: dataId
    };
    fetch('/search-data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataSearch)
    })
        .then(response => response.json())
        .then(data => {
            if (pageFollowing) {
                let currentPage = parseInt(pageFollowing.textContent.trim());
                let parentContainer = pageFollowing.closest('.searchResults-typelist-list');
                let ulElement = parentContainer.querySelector('ul');
                ulElement.innerHTML = '';
                if (element.classList.contains('previous-list') && currentPage > 1) {
                    pageFollowing.textContent = currentPage - 1;
                    const previousPageData = data[currentPage - 2];
                    appendDataToUl(previousPageData);
                } else {
                    pageFollowing.textContent = currentPage + 1;
                    const nextPageData = data[currentPage];
                    appendDataToUl(nextPageData);
                }

                function appendDataToUl(pageData) {
                    ulElement.innerHTML = '';
                    if (pageData) {
                        pageData.forEach(function (item) {
                            let liElement = document.createElement('li');
                            let aElement = document.createElement('a');
                            aElement.textContent = item.title;
                            aElement.href = item.url;
                            aElement.innerHTML += `
                                <svg class="icon icon-angle" aria-hidden="true" focusable="false" width="20" height="20">
                                    <use xlink:href="/bundles/thermorprosite/dist/icons.svg#icon-angle"></use>
                                </svg>
                            `;
                            aElement.charset = "UTF-8";
                            liElement.appendChild(aElement);
                            ulElement.appendChild(liElement);
                        });
                    }

                }

                let nbPagesList = element.closest('.pagination-list');
                let nbPagesElement = nbPagesList.querySelector('.nbPages-list');
                let nbPages = parseInt(nbPagesElement.textContent.trim());
            }
        })
        .catch(error => {
            console.error(error);
        });
}

let dragonModal = document.querySelector('.notifeQueteStatut.dragon');
let earnModal = document.querySelector('.notifeQueteStatut.earn');
const pawDragons = document.querySelectorAll('.drag-click');
let popinGold = document.querySelector('.dragon-gold');
let popinDragon = document.querySelector('.dragon-enable');
if (popinDragon) {
    popinDragon.style.display = "none";
}
if (popinGold) {
    popinGold.style.display = "none";
}
const loadingDragon = document.getElementById('loadingDragon');

pawDragons.forEach(drag => {
    drag.addEventListener('click', function (e) {
        e.preventDefault();
        var typeDragonInput = document.getElementById('typeDragon');
        let typeDragonValue = '';

        if (typeDragonInput) {
            typeDragonValue = typeDragonInput.value.trim();
        }
        hidePaws();
        if (loadingDragon) {
            loadingDragon.style.display = "flex";
        }
        fetch('/info-paw', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({typeDragon: typeDragonValue})
        })
            .then(response => response.json())
            .then(data => {
                loadingDragon.style.display = "none";
                let pawDailyPlay = data?.idOfferTechnical?.pawDailyPlay || false;
                let availableNormalDragon = data?.idOfferTechnical?.DRAGON_PAW || false;
                let availableGoldDragon = data?.idOfferTechnical?.DRAGON_PAW_GOLD || false;
                let status = data?.statusMv || '';
                let pawDailyWin = data?.notifGain?.C_DRAGON_PAW_DAILY_WIN || false;
                let pawGoldDailyWin = data?.notifGain?.C_DRAGON_PAW_GOLD_DAILY_WIN || false;

                if (pawDailyWin) {
                    if (earnModal.getAttribute('aria-hidden') === 'false') {
                        earnModal.setAttribute('aria-hidden', 'true');
                    }

                }
                if (pawGoldDailyWin) {
                    if (earnModal.getAttribute('aria-hidden') === 'false') {
                        earnModal.querySelector('.notifeQueteStatut-title').textContent = "Vous êtes parmi les premiers à avoir retrouvé la trace du dragon d'Or !"
                        earnModal.querySelector('img').setAttribute('src', '/bundles/thermorprosite/images/taverne/dragon/pas_x2/empreintes_x2_or.png')
                        earnModal.setAttribute('aria-hidden', 'true');
                    }
                }

                if (pawDailyWin || pawGoldDailyWin) {
                    let rewards = data.rewards;
                    let counters = data.counters.counter;
                    let totalXpRewards = counters.filter((counter) => counter.name == "C_CUMULXP");
                    let totalLingotsRewards = counters.filter((counter) => counter.name == "C_LINGOT");
                    let cxpRewards = rewards.filter((reward) => reward.counter_name == "C_XP");
                    let clingotRewards = rewards.filter((reward) => reward.counter_name == "C_LINGOT");
                    $('.notifeQueteStatut-item-xp').closest('.notifeQueteStatut-bonus').closest('.super-power').hide();
                    let xpReward = extractRewards(totalXpRewards,cxpRewards);
                    let lingotRewards = extractRewards(totalLingotsRewards,clingotRewards);

                    if(xpReward.reward > 0 || lingotRewards.reward > 0){
                        animateMastersBarWithRewards(data, xpReward, lingotRewards);
                    }
                    return;
                }

                if (pawDailyPlay && typeDragonValue === "normal"){
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinDragon.style.display = "block";
                }else if(pawDailyPlay && typeDragonValue === 'gold'){
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinGold.style.display = "block";
                }
                else if(availableNormalDragon && typeDragonValue === "normal"){
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinDragon.style.display = "block";
                }else if(availableGoldDragon  && typeDragonValue ==='gold') {
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinGold.style.display = "block";
                }else if(status === 3 && typeDragonValue === "normal"){
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinDragon.style.display = "block";
                }else if(status === 3  && typeDragonValue ==='gold') {
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinGold.style.display = "block";
                }
            })
            .catch(error => {
                console.error(error);
                loadingDragon.style.display = "none";
            });
    });
});

function hidePaws(){
    let paws = document.querySelector(".pathDragon");
    let goldPaws = document.querySelector(".goldPathDragon");
    if(paws){
        paws.style.display = 'none';
    }
    if(goldPaws){
        goldPaws.style.display = 'none';
    }
}

function extractRewards(totalValue, data) {
    let reward = 0;
    let bonus = 0;
    let total = 0;

    const amounts = data
        .filter(function(obj){
            for(var i = 0; i < obj.information.length; i++){
                if(obj.information[i].name === "DISPLAY_BENEFITS"){
                    return Boolean(obj.information[i].value);
                }
            }
            return false;
        })
        .map(obj => parseFloat(obj.amount));

    total = parseFloat(totalValue[0].value) - parseFloat(totalValue[0].value_before);
    if(amounts.length > 0){
        bonus = Math.min(...amounts);
    }
    reward = total - bonus

    return { reward: reward, bonus: bonus };
}

function animateMastersBarWithRewards(data, cxp_reward = {}, clingot_reward = {}) {
    const delay = 4000;
    let thermorMasterHeader = document.querySelectorAll('.FullHeaderTop-item--horizonLogo');
    thermorMasterHeader.forEach(function (item) {
        if (window.getComputedStyle(item).display !== "none") {
            let nv = item.querySelector('.EngagementProgramHeader--niveau .desktop-level');
            let nvMb = item.querySelector('.EngagementProgramHeader--niveau .mob-level');
            let counterValue = item.querySelector('.EngagementProgramHeader--lingot span');
            let counterValueStatut = document.querySelector('.blockMonProfilStatut--niveau .blockMonProfilStatut-count');
            let lingot = item.querySelector('.EngagementProgramHeader--lango span');
            let statusMaxxing = item.querySelector('.EngagementProgramHeader--status .EngagementProgramHeader--statusName');
            let currentStatusMaxxing = item.querySelector('.status-maxxing')?.getAttribute('data-level');
            let currentcounterValue = item.querySelector('.EngagementProgramHeader--lingot')?.getAttribute('data-nextlevel');
            let currentLingot = item.querySelector('.lingot-maxxing')?.getAttribute('data-lingot');
            let logoStatusMaxxing = item.querySelector('.EngagementProgramHeader--statusLogo img');
            let infoBullLogo = item.querySelector('.infoBull--logo img');
            let notifeStatusModal = document.querySelector('.notifeQueteStatut.status');
            let notifeQueteStatuttopstatus = document.querySelector('.notifeQueteStatut-top-status');
            let notifeQueteStatuttoplogo = document.querySelector('.notifeQueteStatut-top-logo');
            const splitlogoStatusMaxxing = logoStatusMaxxing?.getAttribute('src').split('/')
            const srclogoStatusMaxxing = splitlogoStatusMaxxing?.slice(0, splitlogoStatusMaxxing.length - 1).join("/") + "/";
            const splitInfobulllogoStatusMaxxing = logoStatusMaxxing?.getAttribute('src').split('/')
            const srcInfobulllogoStatusMaxxing = splitInfobulllogoStatusMaxxing?.slice(0, splitInfobulllogoStatusMaxxing.length - 1).join("/") + "/";
            notifeStatusModal.setAttribute('aria-hidden', 'false');
            let cxpMaxxingDom = document.querySelector('.cxp-maxxing');
            cxpMaxxingDom.innerHTML = '';

            if (notifeQueteStatuttopstatus !== null) notifeQueteStatuttopstatus.textContent = currentStatusMaxxing
            if (infoBullLogo !== null) infoBullLogo.setAttribute('src', srcInfobulllogoStatusMaxxing + currentStatusMaxxing.toLowerCase() + 'Logo.png');
            if (notifeQueteStatuttoplogo !== null) notifeQueteStatuttoplogo.setAttribute('src', notifeQueteStatuttoplogo?.getAttribute('src') + '/bundles/thermorprosite/images/engagementProgram/' + currentStatusMaxxing?.toLowerCase() + 'Logo.png');

            logoStatusMaxxing?.setAttribute('src', srclogoStatusMaxxing + currentStatusMaxxing.toLowerCase() + '.png')
            let progressLevels = document.querySelectorAll('.progress-level');
            let lastCxp = parseFloat(counterValue?.innerText.split('/')[0].trim());

            /* ************************* c_xp/c_nextlevel ********************************** */
            nv.textContent = "Niveau " + data.NIVEAU;
            statusMaxxing.textContent = data.STATUT.charAt(0).toUpperCase() + data.STATUT.substring(1).toLowerCase();
            notifeQueteStatuttopstatus.textContent = data.STATUT.charAt(0).toUpperCase() + data.STATUT.substring(1).toLowerCase();
            lingot.textContent = Math.round(parseFloat(data.C_LINGOT));
            nvMb.textContent = "N." + data.NIVEAU + "-";
            counterValue.innerHTML = Math.round(parseFloat(data.C_XP)) + '/' + Math.round(parseFloat(data.C_XP) + parseFloat(data.C_NEXTLEVEL));
            if (counterValueStatut !== null) counterValueStatut.innerHTML = data.C_XP + '/' + (parseFloat(data.C_XP) + parseFloat(data.C_NEXTLEVEL));

            let newCounterValue = (data.C_XP + '/' + (parseFloat(data.C_XP) + parseFloat(data.C_NEXTLEVEL))).split('/')[1].replace('\n', '').trim();
            let gainCounterValue = newCounterValue - currentcounterValue;
            gainCounterValue = parseFloat(gainCounterValue).toFixed(2);
            let newNiveauBar = parseFloat(parseFloat(data.C_XP) / parseFloat(newCounterValue) * 100);

            if ((currentcounterValue !== newCounterValue) && (gainCounterValue !== 0)) {
                progressLevels.forEach(function (progressLevel) {
                    updateProgressBar(progressLevel, newNiveauBar);
                });
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML = ""
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                                <div class="EngagementProgramHeader--red-bg">
                                    <div class="EngagementProgramHeader--scintillement level">
                                        <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                        <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                        <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                        <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                                    </div>
                                        <div class="EngagementProgramHeader--level-coefficient">
                                            <div class="blurred-overlay"></div>
                                                <span class="level-coefficient">
                                                    ${gainCounterValue}
                                                </span>
                                            </div>
                                </div>`;
            }

            /* ************************** levelMaxxing  */
            let currentLevelMaxxing = item.querySelector('.cxp-maxxing')?.getAttribute('data-currentLevel');
            let newLevelMaxxing = data.NIVEAU;
            let passNewLevel = item.classList.contains('FullHeaderTop-item--horizonLogo-mobile') ? "Bravo, niveau" : "Vous avez atteint le niveau";


            if (currentLevelMaxxing !== newLevelMaxxing) {
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML = ""
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                            <div class="EngagementProgramHeader--red-bg nextLevel">
                                <div class="EngagementProgramHeader--scintillement level">
                                    <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                                </div>
                                <p>${passNewLevel} ${newLevelMaxxing} !</p>
                            </div>
                            `
            }

            /* **************************  StatusMaxxing   */
            let newStatusMaxxing = data.STATUT.charAt(0).toUpperCase() + data.STATUT.substring(1).toLowerCase();
            if (currentStatusMaxxing !== newStatusMaxxing) {
                let notifStatusLingots = document.querySelector('.notifeQueteStatut-item-lingots .notifeQueteStatut-coef')?.firstElementChild;
                notifStatusLingots.innerHTML = data.SUPERPOUVOIR.LINGOTS + '%';
                let notifStatusXp = document.querySelector('.notifeQueteStatut-item-xp .notifeQueteStatut-coef')?.firstElementChild;
                notifStatusXp.innerHTML = data.SUPERPOUVOIR.XP + '%';

                // Change aria-hidden to "false" to show the modal
                if (notifeStatusModal.getAttribute('aria-hidden') === 'false') {
                    setTimeout(function () {
                        notifeStatusModal.setAttribute('aria-hidden', 'true');
                    }, 5000);
                }

                if ((data.SUPERPOUVOIR.LINGOTS === 0 || data.SUPERPOUVOIR.XP === 0) || (data.SUPERPOUVOIR.LINGOTS == '' || data.SUPERPOUVOIR.XP == '')) {
                    document.querySelector('.notifeQueteStatut.status').classList.add('zeroBonus');
                    document.querySelector('.infoBull--desc').style.display = 'none';
                }

                notifeStatusModal.setAttribute('aria-hidden', 'false');
                logoStatusMaxxing.setAttribute('src', srclogoStatusMaxxing + newStatusMaxxing.toLowerCase() + '.png');
                notifeStatusModal.querySelector('.notifeQueteStatut-top-logo').setAttribute('src', "/bundles/thermorprosite/images/engagementProgram/" + newStatusMaxxing.toLowerCase() + 'Logo.png');
                infoBullLogo.setAttribute('src', srcInfobulllogoStatusMaxxing + newStatusMaxxing.toLowerCase() + 'Logo.png');
                item.querySelector('.status-maxxing').innerHTML = ""
                item.querySelector('.status-maxxing').innerHTML += `
                     <div class="EngagementProgramHeader--red-bg">
                         <div class="EngagementProgramHeader--scintillement">
                             <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                             <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                             <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                         </div>
                         <div class="EngagementProgramHeader--text-animation">
                             <span class="currentStatus"> ${currentStatusMaxxing} </span>
                             <span class="nextStatus"> ${newStatusMaxxing} </span>
                         </div>
                     </div>`;
            }
            /* ****************** LingotMaxxing ************************** */
            let newLingot = data.C_LINGOT;
            if (parseFloat(currentLingot) !== parseFloat(newLingot)) {
                item.querySelector('.lingot-maxxing').innerHTML = ""
                item.querySelector('.lingot-maxxing').innerHTML += `
                     <div class="EngagementProgramHeader--red-bg">
                         <span class="EngagementProgramHeader--langoLogo-animation"><img src="/bundles/thermorprosite/icons/lingoWhite.svg" /></span>
                          <div class="EngagementProgramHeader--scintillement">
                              <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                              <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                              <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                              <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                          </div>
                          <div class="EngagementProgramHeader--level-coefficient">
                              <span class="lango-coefficient"> ${clingot_reward.reward ? clingot_reward.reward.toFixed(2) : (newLingot - currentLingot)} </span>
                          </div>
                        </div>`;
                if(clingot_reward.bonus) {
                    setTimeout(() => {
                        item.querySelector('.lingot-maxxing').innerHTML += `
                        <div class="EngagementProgramHeader--red-bg">
                         <span class="EngagementProgramHeader--langoLogo-animation"><img src="/bundles/thermorprosite/icons/lingoWhite.svg" /></span>
                          <div class="EngagementProgramHeader--scintillement">
                              <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                              <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                              <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                              <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                          </div>
                          <div class="EngagementProgramHeader--level-coefficient">
                              <span class="lango-coefficient"> ${clingot_reward.bonus ? clingot_reward.bonus.toFixed(2) : 0} </span>
                          </div>
                        </div>`;
                    }, delay);
                }
            }


            let newCXP = data.C_XP;
            let gainCXP = newCXP - lastCxp < 0 ? parseFloat(newCXP).toFixed(2) : parseFloat(newCXP - lastCxp).toFixed(2);

            if (lastCxp !== newCXP && gainCXP !== 0) {
                function updateProgressBar(progressLevel, niveauBar) {
                    if (niveauBar > 99) {
                        progressLevel.style.width = "0%";
                    } else {
                        progressLevel.style.width = niveauBar.toString() + "%";
                    }
                }

                progressLevels.forEach(function (progressLevel) {
                    updateProgressBar(progressLevel, newNiveauBar);
                });
                var gainCXPPartieEntiere = Math.floor(gainCXP);
                var gainCXPPartieEntiereToShow = gainCXPPartieEntiere === 0 ? "0.00" : gainCXPPartieEntiere.toFixed(2);
                var gainCXPPartieDecimale = parseFloat(gainCXP - gainCXPPartieEntiere).toFixed(2);

                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML = ""
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                        <div class="EngagementProgramHeader--red-bg">
                            <div class="EngagementProgramHeader--scintillement level">
                                <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                            </div>
                                <div class="EngagementProgramHeader--level-coefficient">
                                    <div class="blurred-overlay"></div>
                                        <span class="level-coefficient">
                                            ${cxp_reward.reward ? cxp_reward.reward.toFixed(2) : gainCXPPartieEntiereToShow}
                                        </span>
                                    </div>
                        </div>`;
                if(cxp_reward.bonus || gainCXPPartieDecimale > 0){
                    setTimeout(() => {
                        item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                        <div class="EngagementProgramHeader--red-bg">
                            <div class="EngagementProgramHeader--scintillement level">
                                <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                            </div>
                                <div class="EngagementProgramHeader--level-coefficient">
                                    <div class="blurred-overlay"></div>
                                        <span class="level-coefficient">
                                            ${cxp_reward.bonus ? cxp_reward.bonus.toFixed(2) : gainCXPPartieDecimale}
                                        </span>
                                    </div>
                        </div>`;
                    }, delay);
                }
            }
        }
    });
}
