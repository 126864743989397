import $ from "jquery";

let dynamicPrizes = [];
let indexLost = 0;
let NotifeWheel = document.querySelector('.notifeQueteStatut.wheel');
let NotifeWheelMessage = document.querySelector('.notifeQueteStatut.wheel .notifeQueteStatut-title');
let NotifeWheelTitle = document.querySelector('.notifeQueteStatut.wheel .notifeQueteStatut-top-title');
let NotifeWheelImg = document.querySelector('.notifeQueteStatut.wheel .image-earn');

const enableWheel = async () => {
    await fetch('/get-wheel-configuration', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(data => {
            let configuration = data;
            let blocPieces = document.querySelector('.blockMoney--value');
            if(blocPieces){
                blocPieces.innerHTML = parseInt(data.silverCoins) + '<img src="/bundles/thermorprosite/images/taverne/roue/piece.png"/>';
            }

            Object.keys(configuration.wheel).forEach(function (key, index) {
                let reaction = 'dancing';
                let color = "rgb(70, 13, 13, 0.8)";
                if (index % 2 === 0) {
                    color = "rgb(70, 13, 13, 0.9)";
                }
                let probability = parseFloat(configuration.wheel[key].percentWin);
                if (parseFloat(probability) < 1.0) {
                    reaction = 'shocked'
                } else if (parseFloat(probability) > 1.0 && parseFloat(probability) < 5.0) {
                    reaction = 'laughing';
                }
                if(configuration.wheel[key].name === "WOF_LOST"){
                    indexLost = index;
                }
                dynamicPrizes.push(
                    {
                        text: configuration.wheel[key].label,
                        color: color,
                        reaction: reaction,
                        probability: probability/100,
                        img: configuration.wheel[key].image,
                        name: configuration.wheel[key].name,
                        title: configuration.wheel[key].title,
                        subtitle: configuration.wheel[key].subtitle,
                        counterStoreMovement: configuration.wheel[key].counterStoreMovement
                    }
                );
            });
            if(configuration.wheelDailyCounter){
                return true;
            } else if(configuration.silverCoins > 10) {
                document.querySelector('.infoBull--wrapper.silver').classList.add('show');
                return true;
            } else {
                throw new Error();
            }
        })
        .catch(error => {
            throw new Error(error);
        });
};
const wheel = document.querySelector(".deal-wheel");
if(wheel){
    const spinner = wheel.querySelector(".spinner");
    const trigger = wheel.querySelector(".btn-spin .img2");
    const ticker = wheel.querySelector(".ticker");
    const spinClass = "is-spinning";
    const selectedClass = "selected";
    const spinnerStyles = window.getComputedStyle(spinner);
    let tickerAnim;
    let rotation = 0;
    let currentSlice = 0;
    let prizeNodes;

    const createPrizeNodes = () => {
        const prizeSlice = 360 / dynamicPrizes.length;
        const prizeOffset = Math.floor(180 / dynamicPrizes.length);
        dynamicPrizes.forEach(({ text, color, reaction, img }, i) => {
            const rotation = ((prizeSlice * i) * -1) - prizeOffset;
            spinner.insertAdjacentHTML(
                "beforeend",
                `<li class="prize" data-reaction=${reaction} style="--rotate: ${rotation}deg">
                    <img src="${img}">
                </li>`
            );
        });
    };

    const createConicGradient = () => {
        spinner.setAttribute(
            "style",
            `background: conic-gradient(
                    from -90deg,
                    ${dynamicPrizes
                .map(({ color }, i) => `${color} 0 ${(100 / dynamicPrizes.length) * (dynamicPrizes.length - i)}%`)
                .reverse()
            }
                  );`
        );
    };

    const setupWheel = () => {
        createPrizeNodes();
        prizeNodes = wheel.querySelectorAll(".prize");
    };

    const spinertia = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const runTickerAnimation = () => {
        const prizeSlice = 360 / dynamicPrizes.length;
        const values = spinnerStyles.transform.split("(")[1].split(")")[0].split(",");
        const a = values[0];
        const b = values[1];
        let rad = Math.atan2(b, a);

        if (rad < 0) rad += (2 * Math.PI);

        const angle = Math.round(rad * (180 / Math.PI));
        const slice = Math.floor((angle + prizeSlice / 2) % 360 / prizeSlice); // Adjust to account for the top position

        if (currentSlice !== slice) {
            ticker.style.animation = "none";
            setTimeout(() => ticker.style.animation = null, 10);
            currentSlice = slice;
        }

        tickerAnim = requestAnimationFrame(runTickerAnimation);
    };

    const selectPrize = () => {
        const prizeSlice = 360 / dynamicPrizes.length;
        const selected = Math.floor(rotation / prizeSlice) % dynamicPrizes.length;
        prizeNodes[selected].classList.add(selectedClass);
    };

    const weightedRandomPrize = () => {
        const totalProbability = dynamicPrizes.reduce((sum, prize) => sum + prize.probability, 0);
        const random = Math.random() * totalProbability;
        let accumulated = 0;

        for (let i = 0; i < dynamicPrizes.length; i++) {
            accumulated += dynamicPrizes[i].probability;
            if (random <= accumulated) {
                return i;
            }
        }
    };

    function forceEndLost(){
        disableWheelEvent();
        trigger.classList.remove('animatIt');
        wheel.querySelector('.infoBull--wrapper.no-replay').classList.remove('show');
        wheel.querySelector('.infoBull--wrapper.silver').classList.remove('show');
        wheel.querySelector('.infoBull--wrapper.replay').classList.remove('show');

        const targetPrizeIndex = indexLost;

        const prizeSlice = 360 / dynamicPrizes.length;
        const targetRotation = targetPrizeIndex * prizeSlice + (Math.random() * prizeSlice);
        rotation = targetRotation; // Assure plusieurs rotations complètes and adjust to align prize at the top

        prizeNodes.forEach((prize) => prize.classList.remove(selectedClass));
        wheel.classList.add(spinClass);
        spinner.style.setProperty("--rotate", rotation);
        ticker.style.animation = "none";
        runTickerAnimation();

        trigger.removeEventListener("animationend", onDropAnimationEnd);
    }

    function onDropAnimationEnd() {
        disableWheelEvent();
        trigger.classList.remove('animatIt');
        wheel.querySelector('.infoBull--wrapper.no-replay').classList.remove('show');
        wheel.querySelector('.infoBull--wrapper.silver').classList.remove('show');
        wheel.querySelector('.infoBull--wrapper.replay').classList.remove('show');

        const targetPrizeIndex = weightedRandomPrize();

        const prizeSlice = 360 / dynamicPrizes.length;
        const prizeOffset = Math.floor(180 / dynamicPrizes.length);
        const targetRotation = targetPrizeIndex * prizeSlice + (Math.random() * prizeSlice);
        rotation = 360 * 20 + targetRotation; // Assure plusieurs rotations complètes and adjust to align prize at the top

        prizeNodes.forEach((prize) => prize.classList.remove(selectedClass));
        wheel.classList.add(spinClass);
        spinner.style.setProperty("--rotate", rotation);
        ticker.style.animation = "none";
        runTickerAnimation();
        sendPostRequest({
            'earn' : dynamicPrizes[targetPrizeIndex].name,
            'counterStoreMovement' : dynamicPrizes[targetPrizeIndex].counterStoreMovement
        });

        trigger.removeEventListener("animationend", onDropAnimationEnd);
    }

    spinner.addEventListener("transitionend", () => {
        cancelAnimationFrame(tickerAnim);
        trigger.disabled = false;
        trigger.focus();
        rotation %= 360;
        selectPrize();
        wheel.classList.remove(spinClass);
        spinner.style.setProperty("--rotate", rotation);
    });

    function sendPostRequest(dataToSend) {
        const url = '/get-wof-transac';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response);
            })
            .then(data => {
                let blocPieces = document.querySelector('.blockMoney--value');
                if(blocPieces){
                    blocPieces.innerHTML = parseInt(data.silverCoins) + '<img src="/bundles/thermorprosite/images/taverne/roue/piece.png"/>';
                }
                NotifeWheel.querySelector('.notifeQueteStatut-close').removeEventListener("click", displaySilverInfo);
                NotifeWheel.querySelector('.notifeQueteStatut-close').removeEventListener("click", displayNoReplayInfo);
                NotifeWheel.querySelector('.notifeQueteStatut-close').removeEventListener("click", displayReplayInfo);
                if(dataToSend.earn !== "WOF_LOST" ){
                    NotifeWheelTitle.textContent = data.title;
                    NotifeWheelMessage.textContent = data.desc;
                    NotifeWheelImg.setAttribute('src', data.image);
                }else{
                    NotifeWheelTitle.textContent = "RETENTEZ VOTRE CHANCE TRÈS PROCHAINEMENT";
                    NotifeWheelMessage.textContent = "Vous pouvez jouer à nouveau en dépensant vos pièces d\'argent ou attendre demain pour un tour gratuit";
                    NotifeWheelImg.setAttribute('src', "/bundles/thermorprosite/images/taverne/roue/lot-roue/DOMMAGE.png");
                }
                NotifeWheelImg.setAttribute('style', 'max-width:100px;');
                NotifeWheel.setAttribute('aria-hidden', 'true');
                if(dataToSend.earn !== "WOF_FREE_SPIN" ){
                    if(data.silverCoins >= 10){
                        NotifeWheel.querySelector('.notifeQueteStatut-close').addEventListener("click", displaySilverInfo);
                    }else{
                        NotifeWheel.querySelector('.notifeQueteStatut-close').addEventListener("click", displayNoReplayInfo);
                    }
                }else{
                    NotifeWheel.querySelector('.notifeQueteStatut-close').addEventListener("click", displayReplayInfo);
                }
                if(data.rewards){
                    let rewards = data.rewards;
                    let counters = data.counters.counter;
                    let totalXpRewards = counters.filter((counter) => counter.name == "C_CUMULXP");
                    let totalLingotsRewards = counters.filter((counter) => counter.name == "C_LINGOT");
                    let cxpRewards = rewards.filter((reward) => reward.counter_name == "C_XP");
                    let clingotRewards = rewards.filter((reward) => reward.counter_name == "C_LINGOT");
                    $('.notifeQueteStatut-item-xp').closest('.notifeQueteStatut-bonus').closest('.super-power').hide();
                    let xpReward = extractRewards(totalXpRewards,cxpRewards);
                    let lingotRewards = extractRewards(totalLingotsRewards,clingotRewards);

                    if(xpReward.reward > 0 || lingotRewards.reward > 0){
                        animateMastersBarWithRewards(data, xpReward, lingotRewards);
                    }
                }
            })
            .catch(error => {
                forceEndLost();
                NotifeWheel.querySelector('.notifeQueteStatut-close').removeEventListener("click", displaySilverInfo);
                NotifeWheel.querySelector('.notifeQueteStatut-close').removeEventListener("click", displayNoReplayInfo);
                NotifeWheel.querySelector('.notifeQueteStatut-close').removeEventListener("click", displayReplayInfo);
                NotifeWheelTitle.textContent = "RETENTEZ VOTRE CHANCE TRÈS PROCHAINEMENT";
                NotifeWheelMessage.textContent = "Vous pouvez jouer à nouveau en dépensant vos pièces d\'argent ou attendre demain pour un tour gratuit";
                NotifeWheelImg.setAttribute('src', "/bundles/thermorprosite/images/taverne/roue/lot-roue/DOMMAGE.png");
                NotifeWheelImg.setAttribute('style', 'max-width:100px;');
                NotifeWheel.setAttribute('aria-hidden', 'true');
                NotifeWheel.querySelector('.notifeQueteStatut-close').addEventListener("click", displayNoReplayInfo);
            });
    }

    const displayReplayInfo = () => {
        enableWheelEvent();
        wheel.querySelector('.infoBull--wrapper.replay').classList.add('show');
    }

    const displaySilverInfo = () => {
        enableWheelEvent();
        wheel.querySelector('.infoBull--wrapper.silver').classList.add('show');
    }

    const displayNoReplayInfo = () => {
        disableWheelEvent();
        wheel.querySelector('.infoBull--wrapper.no-replay').classList.add('show');
    }

    const dragoverEvent = () => {
        event.preventDefault();
    }

    const clickAnimEvent = () => {
        event.preventDefault();

        trigger.classList.add('animatIt');
        trigger.disabled = true;

        trigger.addEventListener("animationend", onDropAnimationEnd);
    }

    const dragStartEvent = (event) => {
        event.dataTransfer.setData('Test', 'Some data');
        event.dataTransfer.setDragImage(img, 0, 0);
        if (trigger.disabled) {
            return;
        }
        trigger.classList.add('down');
    }

    const touchStartEvent = (event) => {
        event.preventDefault();
        if (trigger.disabled) {
            return;
        }
        trigger.classList.add('down');
    }

    const dragEndEvent = (event) => {
        event.preventDefault();
        if (trigger.disabled) {
            return;
        }

        trigger.classList.remove('down');
        trigger.disabled = true;
        onDropAnimationEnd();
    }

    const touchEndEvent = (event) => {
        event.preventDefault();
        if (trigger.disabled) {
            return;
        }
        trigger.classList.remove('down');
        trigger.disabled = true;
        onDropAnimationEnd();
    }

    function enableWheelEvent(){
        const img = document.createElement('img');
        const transparentImage = '/bundles/thermorprosite/images/taverne/transparent.png';
        img.src = window.location.origin + transparentImage;

        trigger.addEventListener("dragover", dragoverEvent);

        trigger.addEventListener("click", clickAnimEvent);

        trigger.addEventListener("dragstart", dragStartEvent);

        trigger.addEventListener("touchstart", touchStartEvent, { passive: false });

        trigger.addEventListener("dragend", dragEndEvent);

        trigger.addEventListener("touchend", touchEndEvent);
    }

    function disableWheelEvent(){
        trigger.removeEventListener("dragover", dragoverEvent);

        trigger.removeEventListener("click", clickAnimEvent);

        trigger.removeEventListener("dragstart", dragStartEvent);

        trigger.removeEventListener("touchstart", touchStartEvent, { passive: false });

        trigger.removeEventListener("dragend", dragEndEvent);

        trigger.removeEventListener("touchend", touchEndEvent);
    }

    enableWheel().then(
        function() {
            enableWheelEvent()
            setupWheel();
        }
    ).catch(error => {
        wheel.querySelector('.infoBull--wrapper.no-replay').classList.add('show');
        setupWheel();
    });
}

function extractRewards(totalValue, data) {
    let reward = 0;
    let bonus = 0;
    let total = 0;

    const amounts = data
        .filter(function(obj){
            for(var i = 0; i < obj.information.length; i++){
                if(obj.information[i].name === "DISPLAY_BENEFITS"){
                    return Boolean(obj.information[i].value);
                }
            }
            return false;
        })
        .map(obj => parseFloat(obj.amount));

    total = parseFloat(totalValue[0].value) - parseFloat(totalValue[0].value_before);
    if(amounts.length > 0){
        bonus = Math.min(...amounts);
    }
    reward = total - bonus

    return { reward: reward, bonus: bonus };
}

function animateMastersBarWithRewards(data, cxp_reward = {}, clingot_reward = {}) {
    const delay = 4000;
    let thermorMasterHeader = document.querySelectorAll('.FullHeaderTop-item--horizonLogo');
    thermorMasterHeader.forEach(function (item) {
        if (window.getComputedStyle(item).display !== "none") {
            let nv = item.querySelector('.EngagementProgramHeader--niveau .desktop-level');
            let nvMb = item.querySelector('.EngagementProgramHeader--niveau .mob-level');
            let counterValue = item.querySelector('.EngagementProgramHeader--lingot span');
            let counterValueStatut = document.querySelector('.blockMonProfilStatut--niveau .blockMonProfilStatut-count');
            let lingot = item.querySelector('.EngagementProgramHeader--lango span');
            let statusMaxxing = item.querySelector('.EngagementProgramHeader--status .EngagementProgramHeader--statusName');
            let currentStatusMaxxing = item.querySelector('.status-maxxing')?.getAttribute('data-level');
            let currentcounterValue = item.querySelector('.EngagementProgramHeader--lingot')?.getAttribute('data-nextlevel');
            let currentLingot = item.querySelector('.lingot-maxxing')?.getAttribute('data-lingot');
            let logoStatusMaxxing = item.querySelector('.EngagementProgramHeader--statusLogo img');
            let infoBullLogo = item.querySelector('.infoBull--logo img');
            let notifeStatusModal = document.querySelector('.notifeQueteStatut.status');
            let notifeQueteStatuttopstatus = document.querySelector('.notifeQueteStatut-top-status');
            let notifeQueteStatuttoplogo = document.querySelector('.notifeQueteStatut-top-logo');
            const splitlogoStatusMaxxing = logoStatusMaxxing?.getAttribute('src').split('/')
            const srclogoStatusMaxxing = splitlogoStatusMaxxing?.slice(0, splitlogoStatusMaxxing.length - 1).join("/") + "/";
            const splitInfobulllogoStatusMaxxing = logoStatusMaxxing?.getAttribute('src').split('/')
            const srcInfobulllogoStatusMaxxing = splitInfobulllogoStatusMaxxing?.slice(0, splitInfobulllogoStatusMaxxing.length - 1).join("/") + "/";
            notifeStatusModal.setAttribute('aria-hidden', 'false');
            let cxpMaxxingDom = document.querySelector('.cxp-maxxing');
            cxpMaxxingDom.innerHTML = '';

            if (notifeQueteStatuttopstatus !== null) notifeQueteStatuttopstatus.textContent = currentStatusMaxxing
            if (infoBullLogo !== null) infoBullLogo.setAttribute('src', srcInfobulllogoStatusMaxxing + currentStatusMaxxing.toLowerCase() + 'Logo.png');
            if (notifeQueteStatuttoplogo !== null) notifeQueteStatuttoplogo.setAttribute('src', notifeQueteStatuttoplogo?.getAttribute('src') + '/bundles/thermorprosite/images/engagementProgram/' + currentStatusMaxxing?.toLowerCase() + 'Logo.png');

            logoStatusMaxxing?.setAttribute('src', srclogoStatusMaxxing + currentStatusMaxxing.toLowerCase() + '.png')
            let progressLevels = document.querySelectorAll('.progress-level');
            let lastCxp = parseFloat(counterValue?.innerText.split('/')[0].trim());

            /* ************************* c_xp/c_nextlevel ********************************** */
            nv.textContent = "Niveau " + data.NIVEAU;
            statusMaxxing.textContent = data.STATUT.charAt(0).toUpperCase() + data.STATUT.substring(1).toLowerCase();
            notifeQueteStatuttopstatus.textContent = data.STATUT.charAt(0).toUpperCase() + data.STATUT.substring(1).toLowerCase();
            lingot.textContent = Math.round(parseFloat(data.C_LINGOT));
            nvMb.textContent = "N." + data.NIVEAU + "-";
            counterValue.innerHTML = Math.round(parseFloat(data.C_XP)) + '/' + Math.round(parseFloat(data.C_XP) + parseFloat(data.C_NEXTLEVEL));
            if (counterValueStatut !== null) counterValueStatut.innerHTML = data.C_XP + '/' + (parseFloat(data.C_XP) + parseFloat(data.C_NEXTLEVEL));

            let newCounterValue = (data.C_XP + '/' + (parseFloat(data.C_XP) + parseFloat(data.C_NEXTLEVEL))).split('/')[1].replace('\n', '').trim();
            let gainCounterValue = newCounterValue - currentcounterValue;
            gainCounterValue = parseFloat(gainCounterValue).toFixed(2);
            let newNiveauBar = parseFloat(parseFloat(data.C_XP) / parseFloat(newCounterValue) * 100);

            if ((currentcounterValue !== newCounterValue) && (gainCounterValue !== 0)) {
                progressLevels.forEach(function (progressLevel) {
                    updateProgressBar(progressLevel, newNiveauBar);
                });
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML = ""
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                                <div class="EngagementProgramHeader--red-bg">
                                    <div class="EngagementProgramHeader--scintillement level">
                                        <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                        <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                        <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                        <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                                    </div>
                                        <div class="EngagementProgramHeader--level-coefficient">
                                            <div class="blurred-overlay"></div>
                                                <span class="level-coefficient">
                                                    ${gainCounterValue}
                                                </span>
                                            </div>
                                </div>`;
            }

            /* ************************** levelMaxxing  */
            let currentLevelMaxxing = item.querySelector('.cxp-maxxing')?.getAttribute('data-currentLevel');
            let newLevelMaxxing = data.NIVEAU;
            let passNewLevel = item.classList.contains('FullHeaderTop-item--horizonLogo-mobile') ? "Bravo, niveau" : "Vous avez atteint le niveau";


            if (currentLevelMaxxing !== newLevelMaxxing) {
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML = ""
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                            <div class="EngagementProgramHeader--red-bg nextLevel">
                                <div class="EngagementProgramHeader--scintillement level">
                                    <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                                </div>
                                <p>${passNewLevel} ${newLevelMaxxing} !</p>
                            </div>
                            `
            }

            /* **************************  StatusMaxxing   */
            let newStatusMaxxing = data.STATUT.charAt(0).toUpperCase() + data.STATUT.substring(1).toLowerCase();
            if (currentStatusMaxxing !== newStatusMaxxing) {
                let notifStatusLingots = document.querySelector('.notifeQueteStatut-item-lingots .notifeQueteStatut-coef')?.firstElementChild;
                notifStatusLingots.innerHTML = data.SUPERPOUVOIR.LINGOTS + '%';
                let notifStatusXp = document.querySelector('.notifeQueteStatut-item-xp .notifeQueteStatut-coef')?.firstElementChild;
                notifStatusXp.innerHTML = data.SUPERPOUVOIR.XP + '%';

                // Change aria-hidden to "false" to show the modal
                if (notifeStatusModal.getAttribute('aria-hidden') === 'false') {
                    setTimeout(function () {
                        notifeStatusModal.setAttribute('aria-hidden', 'true');
                    }, 5000);
                }

                if ((data.SUPERPOUVOIR.LINGOTS === 0 || data.SUPERPOUVOIR.XP === 0) || (data.SUPERPOUVOIR.LINGOTS == '' || data.SUPERPOUVOIR.XP == '')) {
                    document.querySelector('.notifeQueteStatut.status').classList.add('zeroBonus');
                    document.querySelector('.infoBull--desc').style.display = 'none';
                }

                notifeStatusModal.setAttribute('aria-hidden', 'false');
                logoStatusMaxxing.setAttribute('src', srclogoStatusMaxxing + newStatusMaxxing.toLowerCase() + '.png');
                notifeStatusModal.querySelector('.notifeQueteStatut-top-logo').setAttribute('src', "/bundles/thermorprosite/images/engagementProgram/" + newStatusMaxxing.toLowerCase() + 'Logo.png');
                infoBullLogo.setAttribute('src', srcInfobulllogoStatusMaxxing + newStatusMaxxing.toLowerCase() + 'Logo.png');
                item.querySelector('.status-maxxing').innerHTML = ""
                item.querySelector('.status-maxxing').innerHTML += `
                     <div class="EngagementProgramHeader--red-bg">
                         <div class="EngagementProgramHeader--scintillement">
                             <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                             <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                             <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                         </div>
                         <div class="EngagementProgramHeader--text-animation">
                             <span class="currentStatus"> ${currentStatusMaxxing} </span>
                             <span class="nextStatus"> ${newStatusMaxxing} </span>
                         </div>
                     </div>`;
            }
            /* ****************** LingotMaxxing ************************** */
            let newLingot = data.C_LINGOT;
            if (parseFloat(currentLingot) !== parseFloat(newLingot)) {
                item.querySelector('.lingot-maxxing').innerHTML = ""
                item.querySelector('.lingot-maxxing').innerHTML += `
                     <div class="EngagementProgramHeader--red-bg">
                         <span class="EngagementProgramHeader--langoLogo-animation"><img src="/bundles/thermorprosite/icons/lingoWhite.svg" /></span>
                          <div class="EngagementProgramHeader--scintillement">
                              <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                              <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                              <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                              <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                          </div>
                          <div class="EngagementProgramHeader--level-coefficient">
                              <span class="lango-coefficient"> ${clingot_reward.reward ? clingot_reward.reward.toFixed(2) : (newLingot - currentLingot)} </span>
                          </div>
                        </div>`;
                if(clingot_reward.bonus) {
                    setTimeout(() => {
                        item.querySelector('.lingot-maxxing').innerHTML += `
                        <div class="EngagementProgramHeader--red-bg">
                         <span class="EngagementProgramHeader--langoLogo-animation"><img src="/bundles/thermorprosite/icons/lingoWhite.svg" /></span>
                          <div class="EngagementProgramHeader--scintillement">
                              <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                              <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                              <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                              <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                          </div>
                          <div class="EngagementProgramHeader--level-coefficient">
                              <span class="lango-coefficient"> ${clingot_reward.bonus ? clingot_reward.bonus.toFixed(2) : 0} </span>
                          </div>
                        </div>`;
                    }, delay);
                }
            }


            let newCXP = data.C_XP;
            let gainCXP = newCXP - lastCxp < 0 ? parseFloat(newCXP).toFixed(2) : parseFloat(newCXP - lastCxp).toFixed(2);

            if (lastCxp !== newCXP && gainCXP !== 0) {
                function updateProgressBar(progressLevel, niveauBar) {
                    if (niveauBar > 99) {
                        progressLevel.style.width = "0%";
                    } else {
                        progressLevel.style.width = niveauBar.toString() + "%";
                    }
                }

                progressLevels.forEach(function (progressLevel) {
                    updateProgressBar(progressLevel, newNiveauBar);
                });
                var gainCXPPartieEntiere = Math.floor(gainCXP);
                var gainCXPPartieEntiereToShow = gainCXPPartieEntiere === 0 ? "0.00" : gainCXPPartieEntiere.toFixed(2);
                var gainCXPPartieDecimale = parseFloat(gainCXP - gainCXPPartieEntiere).toFixed(2);

                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML = ""
                item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                        <div class="EngagementProgramHeader--red-bg">
                            <div class="EngagementProgramHeader--scintillement level">
                                <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                            </div>
                                <div class="EngagementProgramHeader--level-coefficient">
                                    <div class="blurred-overlay"></div>
                                        <span class="level-coefficient">
                                            ${cxp_reward.reward ? cxp_reward.reward.toFixed(2) : gainCXPPartieEntiereToShow}
                                        </span>
                                    </div>
                        </div>`;
                if(cxp_reward.bonus || gainCXPPartieDecimale > 0){
                    setTimeout(() => {
                        item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing').innerHTML += `
                        <div class="EngagementProgramHeader--red-bg">
                            <div class="EngagementProgramHeader--scintillement level">
                                <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                            </div>
                                <div class="EngagementProgramHeader--level-coefficient">
                                    <div class="blurred-overlay"></div>
                                        <span class="level-coefficient">
                                            ${cxp_reward.bonus ? cxp_reward.bonus.toFixed(2) : gainCXPPartieDecimale}
                                        </span>
                                    </div>
                        </div>`;
                    }, delay);
                }
            }
        }
    });
}
