import {getCookie} from './../../../../ThermorProSiteBundle/Resources/src/scripts/utilities'

const formContainer = document.querySelectorAll('.promo-landing-page .Ezform')

const init = () => {
  let ibanInputField;
  let ssoCookieValue = getCookie('atlantic_sso_user');
  if (ssoCookieValue === null) {
    ssoCookieValue = ''
  }

  let apiUrl = '/form/get-user-info';
  if (ssoCookieValue !== '') {
    apiUrl += '/'+ssoCookieValue;
  }

  if (!formContainer) return

  formContainer.forEach((formContainer) => {
    getUserData(apiUrl)
      .then((data) => {

        const fields = formContainer.querySelectorAll('.Ezform-field')
        if (fields.length > 0) {
          fields.forEach((fieldContainer) => {
            const fieldInput = fieldContainer.querySelector('input')
            const fieldLabel = fieldContainer.querySelector('label')
            if (fieldInput && fieldLabel && fieldInput.value === '') {
              const fieldIdentifier = fieldLabel.textContent.toLowerCase()
              if (data.hasOwnProperty(fieldIdentifier)) {
                fieldInput.setAttribute('value', data[fieldIdentifier])
              }
            }
            if(fieldContainer.querySelector('.Ezform-field input.iban-type')) {
              ibanInputField = fieldContainer.querySelector('input');
              ibanInputField.addEventListener('blur', function(e) {
                ibanInputField.value = ibanInputField.value.replace(/\s/g, "").toUpperCase();
              })
              ibanInputField.addEventListener('focus', function(e) {
                ibanInputField.parentElement.classList.remove("Ezform-field--invalid");
                if(ibanInputField.nextSibling !== null){
                  ibanInputField.nextSibling.remove();
                }
              })
             }
          })
        }

        const hiddenFields = formContainer.querySelectorAll("input[type='hidden']")
        if (hiddenFields.length > 0) {
          hiddenFields.forEach((field) => {
            if (field.value === '{pro_account_external_id}') {
              field.setAttribute('value', data['pro_account_external_id'])
              return
            }
            if (field.value === '{pro_contact_external_id}') {
              field.setAttribute('value', data['pro_contact_external_id'])
              return
            }
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  })

  async function getUserData(url = '') {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return response.json()
    } else {
      throw new Error("Can't get API data")
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  init()
})
