import utilities from '../../scripts/utilities'
import {sendPostRequest} from '../../scripts/main';

(async function () {
    let favButtons = document.querySelectorAll('[data-document-id]');
    if (favButtons.length < 1) { // check if there's at least one TeaserFav in page
        return;
    }

    const userConnected = document.querySelector('header').classList.contains('user-connected');

    initFavorites(favButtons, userConnected);

    if (userConnected) {
        await setFavoritesInPage();
    }

    function initFavorites(favButtons, userConnected) {
        favButtons.forEach((favButton) => {
            if (!userConnected) {
                favButton.setAttribute('data-popup-link', 'login');
            }
            updateButtonStyle(favButton);
            if (!favButton.dataset.favEventInit) {
                favButton.addEventListener('click', handleFaveClick);
                favButton.setAttribute('data-fav-event-init', 'true');
            }
        });
    }

    function updateButtonStyle(button) {
        if (button.getAttribute('aria-selected') === 'true') {
            button.style.backgroundColor = 'red';
        } else {
            button.style.backgroundColor = '#fff';
        }
    }

    function handleFaveClick(event) {
        const favButton = event.currentTarget;
        if (!userConnected) {
            window.popupLogin.open();
        } else {
            let favoriteId = favButton.dataset.documentId;
            let selected = (favButton.getAttribute('aria-selected') === 'true');
            let toggle = !selected;

            favButton.setAttribute('aria-selected', toggle.toString());
            updateButtonStyle(favButton);

            toggleFavorites(toggle, favoriteId, 'document_docga');

            if (toggle) {
                let dataToSend = {
                    documentIdFav: favButton.getAttribute('data-document-id')
                };
                sendPostRequest(dataToSend);
            }
        }
    }

    function toggleFavorites(isFavorite, id, type = 'document_docga') {
        if (isFavorite) {
            utilities.getData(`/favorites/add/${type}/${id}`);
        } else {
            utilities.getData(`/favorites/delete/${type}/${id}`);
        }
    }

    async function setFavoritesInPage(favoriteType = 'document_docga') {
        fetch(`/favorites/list/${favoriteType}/`)
            .then((response) => response.json())
            .then((json) => {
                if (json.data !== undefined) {
                    json.data.forEach((documentId) => {
                        document.querySelectorAll(`[data-document-id="${documentId}"]`).forEach((element) => {
                            element.setAttribute('aria-selected', 'true');
                            element.style.backgroundColor = 'red';
                        });
                    });
                }
            });
    }

    let addMore = document.getElementById('add-more');
    let totaldata = '';
    let typeDoc = '';

    if (document.querySelector('.data-documents')) {
        let dataDocuments = JSON.parse(document.querySelector('.data-documents').value);
        totaldata = dataDocuments?.resultCount;
        typeDoc = dataDocuments?.type;
    }

    let offsetdata = (typeDoc === 'videos') ? 4 : 8;

    if (addMore) {
        addMore.addEventListener('click', async function () {
            const teaserHiddenElements = document.querySelectorAll('.teaser-hidden');
            let elementsToShow = Math.min((typeDoc === 'videos') ? 4 : 8, teaserHiddenElements.length);
            let newOffset = offsetdata + elementsToShow;

            for (let i = 0; i < elementsToShow; i++) {
                teaserHiddenElements[i].classList.remove('teaser-hidden');
            }

            offsetdata = newOffset;

            if ((typeDoc === 'videos' && (offsetdata >= totaldata)) ||
                (offsetdata >= totaldata)) {
                addMore.style.display = 'none';
            }

            favButtons = document.querySelectorAll('[data-document-id]');
            initFavorites(favButtons, userConnected);

            const videoThumbnails = document.querySelectorAll('.video-pl .BlockVideo-mainiframe');
            videoThumbnails.forEach((thumbnail) => {
                thumbnail.addEventListener('click', function (e) {
                    e.stopPropagation();
                    const mainIframe = `<iframe style="width:95%;margin-top: 70px;height:85%;" class="BlockVideo-mainiframe" src="https://player.vimeo.com/video/${thumbnail.getAttribute('data-video-id')}?autoplay=1" frameborder="0" allow="autoplay; fullscreen" width="100%" height="auto" allowfullscreen></iframe>`;
                    const newDiv = document.getElementById('video-pop');
                    newDiv.style.display = 'block';
                    newDiv.innerHTML = mainIframe;
                    favButtons = document.querySelectorAll('[data-document-id]');
                    initFavorites(favButtons, userConnected);
                });
            });
        });
    }
})();


