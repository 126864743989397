// Enable SVG <use xlink:href> support in IE11
import svg4everybody from 'svg4everybody'
svg4everybody()

// Import each icon that we want to include in our SVG sprite
// (built by Webpack + svg-sprite-loader)
import './profile.svg'
import './cart.svg'
import './circled-plus.svg'
import './circled-minus.svg'
import './call.svg'
import './calendar.svg'
import './column.svg'
import './contentblockleft.svg'
import './contentblockfull.svg'
import './contentblockfour.svg'
import './expert.svg'
import './social-linkedin.svg'
import './social-pinterest.svg'
import './social-facebook.svg'
import './social-youtube.svg'
import './linkedin-widget.svg'
import './pinterest-widget.svg'
import './facebook-widget.svg'
import './youtube-widget.svg'
import './messagethin.svg'
import './toolsthin.svg'
import './promothin.svg'
import './calendarthin.svg'
import './search.svg'
import './document.svg'
import './timer.svg'
import './favorites.svg'
import './trainingthicker.svg'
import './stardefault.svg'
import './staractive.svg'
import './notice.svg'
import './videoplay.svg'
import './videoplay-outline.svg'
import './angle.svg'
import './angle-left.svg'
import './angle-left-white.svg'
import './angle-thin-down.svg'
import './anglegray2.svg'
import './star.svg'
import './starbanner.svg'
import './download.svg'
import './file.svg'
import './visuels.svg'
import './videos.svg'
import './basetarif.svg'
import './backtotop.svg'
import './arrowdownred.svg'
import './arrowup.svg'
import './menu.svg'
import './euro.svg'
import './timerthermor.svg'
import './icontop3.svg'
import './icontop3spare.svg'
import './trainingtype.svg'
import './twopeople.svg'
import './counter.svg'
import './staravis.svg'
import './bubble.svg'
import './mapwithpoints.svg'
import './check.svg'
import './difficulte1.svg'
import './difficulte2.svg'
import './difficulte3.svg'
import './temps_remplacement.svg'
import './icon_filled.svg'
import './delivery.svg'
import './accordion.svg'
import './garantie.svg'
import './anglegray2.svg'
import './bin.svg'
import './lock.svg'
import './time.svg'
import './settings.svg'
import './truck.svg'
import './thermorlion-thicker.svg'
import './storelocator.svg'
import './videos.svg'
import './mail.svg'
import './tools.svg'
import './logonobaseline.svg'
import './favselected.svg'
import './favunselected.svg'
import './link.svg'
import './visiblitystation.svg'
import './visiblityexpert.svg'
import './visiblityshowroom.svg'
import './visibilitycalendar.svg'
import './visibilitypin.svg'
import './visibilitychevron.svg'
import "./registeraccount.svg";
import "./eye3.svg";
import './trash.svg'
import './edit.svg'
import './house.svg'
import './building.svg'
import './arrow-down-solid.svg'
import './arrow-down.svg'
import './arrow-up.svg'
import './confort.svg'
import './connecte.svg'
import './environnement.svg'
import './performance.svg'
import './purification.svg'
import './pac-console.svg'
import './pac-gainable.svg'
import './calculator.svg'
import './hexagon.svg'
import './remplacement.svg'
import './circle-check.svg'
import './circle-xmark.svg'
import './piscine.svg'
import './reload.svg'
import './chevron.svg'
import './chevron-down.svg'
import './mask.svg'

// Sites icons
import "./sites-contact.svg";
import "./sites-discussion.svg";
import "./sites-question.svg";
import "./sites-tool.svg";

// Icons used in backoffice

import './bocart.svg'
import './bocommercial.svg'
import './boconfort.svg'
import './bodesign.svg'
import './bodimensions.svg'
import './bodistrib.svg'
import './bodrop.svg'
import './bodrytowel.svg'
import './boeco.svg'
import './boexpertfull.svg'
import './bofaq.svg'
import './boformation.svg'
import './bofrance.svg'
import './bohottowel.svg'
import './boinstaller.svg'
import './boleaf.svg'
import './boordinateur.svg'
import './boparams.svg'
import './boparticulier.svg'
import './bophonetablet.svg'
import './borayon.svg'
import './bosound.svg'
import './botemp.svg'
import './botempup.svg'
import './bothermorlion.svg'
import './botools.svg'
import './botraining.svg'
import './botruck.svg'
import './botvdvd.svg'
import './bowifi.svg'
import './bozoom.svg'


// engagment program

import './Logo-horizontal.svg'
import './bachelor.svg'
import './union.svg'
import './lingoWhite.svg'
import './lango.svg'
import './stars1.svg'
import './stars2.svg'
import './stars3.svg'
import './stars4.svg'
import './arrow-trie.svg'

import './laptop.svg'
import './expertTeck.svg'
import './gamme.svg'
import './image.svg'
import './video.svg'
import './video_tutorial.svg'
import './angle-left-red.svg'
import './close.svg'
import './player.svg'