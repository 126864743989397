const mainDiv = document.querySelector(".main");
if(mainDiv){
    fetch('/display-paw', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify(mainDiv.dataset.contentId)
    })
        .then(response => response.json())
        .then(data => {
            let dragon = null;
            let dragonNormal = document.querySelector(".pathDragon");
            let dragonGold = document.querySelector(".goldPathDragon");
            var input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("id", "typeDragon");

            if(data.display){
                if (data.typeDragon === "normal") {
                    dragonNormal.style.display = "block";
                    input.setAttribute("value", "normal");
                    dragonNormal.parentNode.appendChild(input);
                    dragon = dragonNormal;
                    dragonGold.parentNode.removeChild(dragonGold);
                }else if (data.typeDragon === "gold"){
                    dragonGold.style.display = "block";
                    input.setAttribute("value", "gold");
                    dragonGold.parentNode.appendChild(input);
                    dragon = dragonGold
                    dragonNormal.parentNode.removeChild(dragonNormal);
                }
            }else{
                dragonNormal.parentNode.removeChild(dragonNormal);
                dragonGold.parentNode.removeChild(dragonGold);
            }

            if(dragon) {
                var counter = 1;
                let sizeDragon = '';
                if(window.innerWidth >= 768){
                    sizeDragon = '.pathOr'
                }else{
                    sizeDragon = '.pathOrMob'
                }
                let firstDragonPaw = sizeDragon + counter;
                var elem = dragon.querySelector(firstDragonPaw);
                displayDragonPaw(dragon, sizeDragon, elem, counter);
            }
        });

    function displayDragonPaw(dragon, sizeDragon, elem, counter) {
        elem.style.display = 'block';
        counter += 1;
        let nextDragonPaw = sizeDragon + counter;
        var nextElem = dragon.querySelector(nextDragonPaw);
        if(nextElem){
            setTimeout(function(){
                    displayDragonPaw(dragon, sizeDragon, nextElem, counter);
                },
                500
            );
        }
    }
}
