import utilities from '../../scripts/utilities'

(async function () {
    let favButtons = document.querySelectorAll('[data-document-id]');
    if (favButtons.length < 1) { // check if there's at least one TeaserFav in page
        return;
    }

    const userConnected = document.querySelector('header').classList.contains('user-connected')

    initFavorites(favButtons, userConnected);

    if (userConnected) {
        await setFavoritesInPage()
    }

    function initFavorites(favButtons, userConnected) {
        favButtons.forEach((favButton) => {
            if (!userConnected) {
                favButton.setAttribute('data-popup-link', 'login')
            }
            if (favButton.getAttribute('aria-selected') === 'true') {
                favButton.style.backgroundColor = 'red';
            } else {
                favButton.style.backgroundColor = '#fff';
            }
            favButton.addEventListener('click', () => {
                if (!userConnected) {
                    window.popupLogin.open()
                } else {
                    let favoriteId = favButton.dataset.documentId;
                    let selected = (favButton.getAttribute('aria-selected') === 'true');
                    let toggle = !selected;

                    favButton.setAttribute('aria-selected', toggle.toString());

                    if (toggle) {
                        favButton.style.backgroundColor = 'red';
                    } else {
                        favButton.style.backgroundColor = '#fff';
                    }

                    toggleFavorites(toggle, favoriteId, 'document_docga')
                }
            })
        })

        /***
         * toggleFavorites
         * @param isFavorite (bool) - true given, will add. false given, will remove
         * @param id (int) - Favorite id
         * @param type (string) - Generally "document", but can evolve with other types of object.
         */

        function toggleFavorites(isFavorite, id, type = 'document_docga') {
            if (isFavorite) {
                utilities.getData(`/favorites/add/${type}/${id}`);
            } else {
                utilities.getData(`/favorites/delete/${type}/${id}`);
            }
        }
    }

    async function setFavoritesInPage(favoriteType = 'document_docga') {
        fetch('/favorites/list/' + favoriteType + '/')
            .then((response) => response.json())
            .then((json) => {
                if (json.data !== undefined) {
                    json.data.forEach((documentId) => {
                        document.querySelectorAll('[data-document-id="' + documentId + '"]').forEach((element) => {
                            element.setAttribute('aria-selected', 'true');
                            element.style.backgroundColor = 'red';
                        })
                    });
                }
            });
    }

    var fullURL = window.location.href;
    let addMore = document.getElementById('add-more');
    let divCount = document.getElementById('div-fav');
    let totaldata = '';
    let typeDoc = '';
    let locationId = ''

    if (document.querySelector('.data-documents')) {
        let dataDocuments = JSON.parse(document.querySelector('.data-documents').value);
        totaldata = dataDocuments?.resultCount;
        typeDoc = dataDocuments?.type;
        locationId = dataDocuments?.locationId;
    }
    let offsetdata = '';

    if (typeDoc === 'videos') {
        offsetdata = 4;
    } else {
        offsetdata = 8;
    }

    if (addMore) {
        addMore.addEventListener('click', async function () {
            let isLoading = true;
            let dataOffset = {
                offset: offsetdata,
                fullURL: fullURL,
                totalElement: totaldata,
                type: typeDoc,
                locationId: locationId
            };
            const container = document.getElementById('div-fav');
            const loadingDiv = document.createElement('div');
            loadingDiv.classList.add('loadingImg');
            const img = document.createElement('img');
            img.src = "/bundles/thermorprosite/images/animation/thermor-animation-chargement.svg";
            img.alt = "chargement";
            loadingDiv.appendChild(img);
            container.appendChild(loadingDiv);
            
            await fetch('/more-result', {
                method: 'POST',
                body: JSON.stringify(dataOffset)
            })
                .then(response => response.text())
                .then(data => {
                    isLoading = false;
                    container.insertAdjacentHTML('beforeend', data);
                    if (typeDoc === 'videos') {
                        utilities.setVimeoVideos()
                        offsetdata += 4;
                    }else{
                        offsetdata += 8;
                    }
                    if (typeDoc === 'videos' && (offsetdata + 4 > totaldata)) {
                        addMore.style.display = 'none';
                    }
                    if ( offsetdata > totaldata  ) {
                        addMore.style.display = 'none';
                    }
                    container.removeChild(loadingDiv);
                })
                .catch(error => {
                    console.error('Failed to load more data.', error);
                    container.removeChild(loadingDiv);
                });

            favButtons = document.querySelectorAll('[data-document-id]');
            initFavorites(favButtons, userConnected);
            setFavoritesInPage();
            const videoThumbnails = document.querySelectorAll('.video-pl .BlockVideo-mainiframe');
            videoThumbnails.forEach(function (thumbnail) {
                thumbnail.addEventListener('click', function (e) {
                    e.stopPropagation();
                    const mainIframe = '<iframe style="width:95%;margin-top: 70px;height:85%;" class="BlockVideo-mainiframe" src="https://player.vimeo.com/video/' + thumbnail.getAttribute('data-video-id') + '?autoplay=1'+ '" frameborder="0" allow="autoplay; fullscreen" width="100%" height="auto" allowfullscreen></iframe>';
                    const newDiv = document.getElementById('video-pop');
                    newDiv.style.display = 'block';
                    newDiv.innerHTML = mainIframe;
                    favButtons = document.querySelectorAll('[data-document-id]');
                    initFavorites(favButtons, userConnected);
                    setFavoritesInPage();
                });
            });
        });
    }
})();


