import { Formio } from 'formiojs';
import {deleteCookie} from '../../../scripts/utilities';

let apiUrl = window.location.protocol + "//" + window.location.host;
let urlParams = new URLSearchParams(window.location.search);
let objectString =  {};
let department_pool = "";

function getCookie (name) {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

function converToString(data) {
  Object.keys(data).forEach(k => {
 
  if (k !== 'submit') {
    data[k] = '' + data[k];
  }        
});

 return data;
}

const isPacAirStep1Page = document.querySelector('.pac-air-air--step1');
if (isPacAirStep1Page) {
  if(getCookie("pac_air_air_step1") !== undefined) {
    var myCookie = JSON.parse(getCookie("pac_air_air_step1"));
  }
  await fetch('/dep', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  }).then(function (response) {
    return response.json();
  }).then(function (data) {

    department_pool = Object.entries(data.department).map(([key, value]) => {    
      return {value: Number(key), label: value };
    }).sort((a, b) => {
      const numA = parseInt(a.label.substr(0, 2)); // Extract the numeric part
      const numB = parseInt(b.label.substr(0, 2)); // Extract the numeric part
      return numA - numB;
    });
  });
  setTimeout(() => {
    if(document.querySelector(".formLoader")) document.querySelector(".formLoader").remove();
  }, 200);

  // formio
  Formio.createForm(document.getElementById('pac-air-step1'), {
      components: [
        {
          "input": false,
          "html": "<span>Informations générales</span>",
          "type": "content",
          "key": "content",
          "customClass": "red-block--title",
        },
        {
          components : [      {
            "columns": [
              {
                components: [
                  {
                    tabindex: "1",
                    type: 'textfield',
                    key: 'nom',
                    label: 'Nom du projet',
                    input: true,
                    defaultValue: myCookie?.nom || "",
                    validate: {
                      required: true,
                      maxLength: '30'
                    }
                  },
            
                  {
                    tabindex: "3",
                    type: "select",
                    label: "Type de projet",
                    key: "type",
                    placeholder: "Veuillez sélectionner une option",
                    defaultValue:  myCookie?.type || "",
                    data: {
                      values: [
                        {value: 'maison', label: 'Maison'},
                        {value: 'appartement', label: 'Appartement'}
                      ]
                    },
                    validate: {
                      required: true
                    },
                    dataSrc: "values",
                    template: "<span>{{ item.label }}</span>",
                    input: true
                  },
                  {
                    tabindex: "5",
                    type: "number",
                    label: "Altitude (en m)",
                    key: "altitude",
                    defaultValue: myCookie?.altitude || "",
                    validate: {
                      required: true,
                      integer: true,
                      min: 0,
                      max: 2000
                    },
                    input: true
                  },
                  {
                    tabindex: "7",
                    type: "select",
                    label: "Utilisation de l'appareil",
                    key: "utilisation",
                    defaultValue: myCookie?.utilisation || "",
                    placeholder: "Veuillez sélectionner une option",
                    data: {
                      values: [
                        {value: 'refroidir', label: 'Refroidir uniquement'},
                        {value: 'chauffer-refroidir', label: 'Chauffer et refroidir'},
                        {value: 'chauffer', label: 'Chauffer uniquement'}
                      ]
                    },
                    validate: {
                      required: true
                    },
                    dataSrc: "values",
                    template: "<span>{{ item.label }}</span>",
                    input: true
                  }
                ],
                "width": 6,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "size": "md",
                "currentWidth": 6
              },
              {
                components: [
                  {
                    tabindex: "2",
                    type: "select",
                    label: "Département",
                    key: "departement",
                    defaultValue: (myCookie && myCookie.departement && myCookie.departement > 0 && myCookie.departement <= department_pool.length) ? department_pool[myCookie.departement - 1].value : '',
                    placeholder: "Veuillez sélectionner une option",
                    data: {
                      values: department_pool
                    },
                    validate: {
                      required: true
                    },
                    template: "<span>{{ item.label }}</span>",
                    input: true
                  },
                  {
                    tabindex: "4",
                    type: "select",
                    label: "Date de construction",
                    key: "date_construction",
                    defaultValue: myCookie?.date_construction || "",
                    placeholder: "Veuillez sélectionner une option",
                    data: {
                      values: [
                        {value: '< 1974', label: 'Avant 1974'},
                        {value: '< 1982', label: 'Entre 1971 et 1981'},
                        {value: '> 1982', label: 'Entre 1982 et 1999'},
                        {value: '> 2000', label: 'Entre 2000 et 2004'},
                        {value: '> 2005', label: 'Entre 2005 et 2012'},
                        {value: '> 2013', label: 'Après 2013'}
                      ]
                    },
                    validate: {
                      required: true
                    },
                    dataSrc: "values",
                    template: "<span>{{ item.label }}</span>",
                    input: true
                  },
                  {
                    tabindex: "6",
                    type: "select",
                    label: "Nombre de pièces",
                    key: "nb_pieces",
                    defaultValue: myCookie?.nb_pieces.toString() || "",
                    placeholder: "Veuillez sélectionner une option",
                    data: {
                      values: [
                        {value: '1', label: '1'},
                        {value: '2', label: '2'},
                        {value: '3', label: '3'},
                        {value: '4', label: '4'},
                        {value: '5', label: '5'}
                      ]
                    },
                    validate: {
                      required: true
                    },
                    dataSrc: "values",
                    template: "<span>{{ item.label }}</span>",
                    input: true
                  },
                  {
                    tabindex: "8",
                    type: "select",
                    label: "Conservez vous le système de chauffage déjà en place ?",
                    key: "conserver_chauffage",
                    defaultValue:  myCookie?.conserver_chauffage || "",
                    placeholder: "Veuillez sélectionner une option",
                    data: {
                      values: [
                        {value: '0' , label: 'Non'},
                        {value: '1', label: 'Oui'}
                      ]
                    },
                    conditional: {
                      "json": {
                        "or" : [
                          { "===": [
                            {
                              "var": "data.utilisation"
                            },
                            "chauffer-refroidir"
                          ] },
                          {
                            "===": [
                              {
                                "var": "data.utilisation"
                              },
                              "chauffer"
                            ]
                          }
                        ] 
                      }
                    },
                    validate: {
                      required: true
                    },
                    dataSrc: "values",
                    template: "<span>{{ item.label }}</span>",
                    input: true
                  }
                ],
                "width": 6,
                "offset": 0,
                "push": 0,
                "pull": 0,
                "size": "md",
                "currentWidth": 6
              }
            ],
            "type": "columns",
            "conditional": {
              "show": "",
              "when": null,
              "eq": ""
              },
            "key": "columns",
          }],
         "customClass": "red-block"
        },
       {
        type: 'button',
        action: 'submit',
        label: 'Etape suivante',
        theme: 'primary'
      }
      ]
    },
    {language: 'fr', i18n: { 'fr': { required: 'Ce champ est obligatoire', max: 'Ne peut pas être supérieur à {{max}} .', min: 'Ne peut pas être inférieur à {{min}} .', maxLength: 'Ne peut pas être supérieur à {{length}} caractère.', 'No results found' : 'Aucun résultat pour cette recherche', 'Type to search': 'Écrivez pour lancer une recherche' } }})
    .then(function(form) {
      const submitBtn = document.querySelector('.formio-component-submit').querySelector('button');
      const nb_piece_def_value = form.getComponent("nb_pieces").getValue();
      const piece_select =  document.querySelector('.formio-component-nb_pieces').querySelector('select');

      submitBtn.addEventListener('click', function() {
        const errors = document.querySelectorAll('.formio-errors');
        let brk = true;

        errors.forEach( error => {
           if(error.querySelector('.form-text.error')?.isConnected && brk) {
               
               error.parentNode.scrollIntoView();
               brk = false;
           }
        })
      })

      piece_select.addEventListener('change', function() {
           if(parseInt(this.value) < parseInt(nb_piece_def_value)) {
              deleteCookie('pac_air_air_step2', false, '/')
           }
      })

      form.on('submit', function(submission) {
        let idDim = 0;
        let canBeSaved = true;
        let jsonSubmissionData = converToString(submission.data);

        localStorage.removeItem("SaveDim");
        if(getCookie("pac_air_air_step1") !== undefined) {
          var oldCookie = getCookie("pac_air_air_step1");
          let jsonOldCookie = JSON.parse(oldCookie);
          idDim = jsonOldCookie['idDim'];
          delete jsonOldCookie['canBeSaved'];
          delete jsonOldCookie['idDim'];
          if(!jsonSubmissionData.hasOwnProperty('conserver_chauffage')) {
            jsonSubmissionData['conserver_chauffage'] = '0';
          }
          if (_.isEqual(jsonOldCookie, jsonSubmissionData)) {
            canBeSaved = false;
          }
        }
        if(getCookie("pac_air_air_step2") !== undefined && getCookie("pac_air_air_step1") !== undefined) {
          const cookieJson2 = JSON.parse(getCookie("pac_air_air_step2"));
          let utilisation = form.getComponent('utilisation').getValue();
          let nbPieces =  form.getComponent('nb_pieces').getValue();

          if( utilisation === "refroidir") {
            for (let i = 1; i <= nbPieces; i++) {
              cookieJson2[`Temperature${i}`] = null;
            }
            let objectString = JSON.stringify(cookieJson2);
            document.cookie = "pac_air_air_step2" + "=" + objectString + "; expires="+ (new Date(Date.now()+ 86400*1000)).toUTCString()+ "; path=/";
          }
        }

        jsonSubmissionData['canBeSaved'] = canBeSaved;
        jsonSubmissionData['idDim'] = idDim;
        objectString = JSON.stringify(jsonSubmissionData);
        document.cookie = "pac_air_air_step1=" + objectString + "; expires=" + (new Date(Date.now() + 86400 * 1000)).toUTCString() + "; path=/";

      // Check if the "id" parameter exists
        if (urlParams.has('id')) {
          // Parameter exists, get its value
          var id = urlParams.get('id');
          window.location.href = apiUrl + "/simulhome/pac-air-air/nouvelle-installation/logement-individuel/etape-2" + "?id=" + encodeURIComponent(id);
        } else {
          // Parameter doesn't exist
          window.location.href = apiUrl + "/simulhome/pac-air-air/nouvelle-installation/logement-individuel/etape-2";
        }

      });
  });
}